import { useRouter } from "next/router";
import posthog from "posthog-js";
import { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { formJobQueryParam } from "../../../functions/job";
import {
  calibrateSalary,
  getItem,
  removeItem,
} from "../../../helpers/data_management";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import useTriggerSnackbar from "../../../hooks/useTriggerSnackbar";
import {
  bookmarkCompany,
  unBookmarkCompany,
} from "../../../redux/actions/company_action";
import {
  updateJobAlertDialog,
  updateJobAlertVisitorEmailDialog,
  updateJobAlertVisitorUnsubscribeDialog,
  updateJobAppliedDialog,
} from "../../../redux/actions/dialog_action";
import {
  applyJob,
  bookmarkJob,
  clearJobList,
  createJobAlert,
  fetchSearchSuggestions,
  getJobAlerts,
  getJobV1,
  getJobs,
  getJobsParams,
  unBookmarkJob,
  updateInternshipFilterButtonStatus,
  updateJobAlertToggle,
  updateJobId,
  updateJobListFilter,
  updateSelectedJob,
} from "../../../redux/actions/job_action";
import {
  updateShowSignInModalStatus,
  updateSignInModalSignUpStatus,
  updateSignInText,
  updateSignUpText,
} from "../../../redux/actions/navbar_action";
import {
  checkAuthentication,
  getUser,
  updateCreateResumePopupStatus,
  updateInReviewResumePoupupStatus,
  updateNationalityDialog,
  updateRejectedResumePopupStatus,
} from "../../../redux/actions/user_action";
import { store } from "../../../redux/stores/store";
import * as jobTypes from "../../../redux/types/job_type";
import JobAlertsCreateDialog from "../../profile/JobAlertsCreateDialog/JobAlertsCreateDialog";
import CreateResumePopup from "../../profile/ResumePopups/ApplyJobResumeStatus/CreateResume/CreateResume";
import RejectedResumePopup from "../../profile/ResumePopups/ApplyJobResumeStatus/RejectedResume/RejectedResume";
import ResumeInReviewPopup from "../../profile/ResumePopups/ApplyJobResumeStatus/ResumeInReview/ResumeInReview";
import SharedNavbar2 from "../../shared/SharedNavbar/Navbar";
import AccordionJobList from "../AccordionJobList/AccordionJobList";
import JobAlertDialog from "../JobAlertDialog/JobAlertDialog";
import JobAlertToggle from "../JobAlertToggle/JobAlertToggle";
import JobAlertVisitorUnsubscribeDialog from "../JobAlertVisitorUnsubscribeDialog/JobAlertVisitorUnsubscribeDialog";
import JobAppliedDialog from "../JobAppliedDialog/JobAppliedDialog";
import JobSearchOptions from "../JobSearchOptions/JobSearchOptions";
import SearchBar from "../SearchBar/SearchBar";
import VisitorJobAlertDialog from "../VisitorJobAlertDialog/VisitorJobAlertDialog";
import VisitorJobAlertSuccesDialog from "../VisitorJobAlertDialog/VisitorJobAlertSuccessDialog/VisitorJobAlertSuccessDialog";
import { JobPageContainer, JobSearchContainer, Wrapper } from "./styles";

// Prevent spamming by only passing the criteria only the first event, and ignore all subsequent events
let isSavingJob = false;
let isApplyingJob = false;

function JobListWrapper(props) {
  const dispatch = useDispatch();
  const firstTimeMountLoadingJobs = useRef(true);
  const router = useRouter();
  const [currentId, setCurrentId] = useState(null);
  const [getJobListDone, setGetJobListDone] = useState(false);
  const [values, setValues] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const { width } = values;
  const [appliedJob, setAppliedJob] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [visitorAlert, setVisitorAlert] = useState("");
  const [visitorAlertID, setVisitorAlertID] = useState(null);
  const [autoCreatedAlert, setAutoCreatedAlert] = useState(false);
  const [jobPreferenceToggle, setJobPreferenceToggle] = useState(false);

  const [triggerSnackbarFunc] = useTriggerSnackbar();

  function isTrendingCategories() {
    if (router.query) {
      if (
        router.query.experiences ||
        router.query.job_types ||
        router.query.tracks ||
        router.query.states ||
        router.query.remote_search
      ) {
        return true;
      }
    }
    return false;
  }

  function populateTrendingCategory() {
    return new Promise((resolve) => {
      const experiences = router.query.experiences
        ? JSON.parse(router.query.experiences)
        : "";
      const jobTypes = router.query.job_types
        ? JSON.parse(router.query.job_types)
        : "";
      const tracks = router.query.tracks ? JSON.parse(router.query.tracks) : "";
      const states = router.query.states ? JSON.parse(router.query.states) : "";
      const remoteSearch = router.query.remote_search
        ? router.query.remote_search
        : "";

      let jobFilter = {
        keyword: remoteSearch ? remoteSearch : "",
        trackIds: tracks ? tracks : [],
        stateRegionNames: states ? states : [],
        jobTypeIds: jobTypes ? jobTypes : [],
        experienceIds: experiences ? experiences : [],
        expectedSalary: 0,
      };

      props.updateJobListFilter(jobFilter).then((response) => {
        return resolve(response);
      });
    });
  }

  // Fetch jobs based on page
  useEffect(() => {
    const keywordURL = window.location.href;

    if (keywordURL.includes("/jobs/jobs-malaysia")) {
      const regex = /([\w-%&]+)-jobs-in-([\w-]+)/;
      const match = regex.exec(keywordURL);

      if (match) {
        const jobSegment = match[1].replace(/-/g, " ");
        const locationSegment = match[2].replace(/-/g, " ");

        const extractedString = jobSegment + " " + locationSegment;

        store.getState().jobs.jobListFilter["keyword"] = extractedString;
      } else {
        console.error("No match found");
      }
    }

    if (
      router.asPath.includes("/jobs?") ||
      router.asPath.includes("/job-search?")
    ) {
      store.getState().jobs.fetchingTotalJobsPages = true;
    }
    if (
      props.tracks.length < 1 ||
      props.experienceLevels.length < 1 ||
      props.stateRegions.length < 1 ||
      props.jobTypes.length < 1
    ) {
      return;
    }

    // else
    store.getState().jobs.jobCurrentPage = currentPage;
    const cFilter = { ...store.getState().jobs.jobListFilter };

    let params = formJobQueryParam(cFilter, false);
    clearAndLoadJobs(params);

    return () => {
      // Clear job search keyword in redux when unmount
      store.getState().jobs.jobListFilter = {};
      // Unmount and clear Ashley jobs after navigating out of job listing page
      store.getState().jobs.jobs = [];
    };
  }, []);

  useEffect(() => {
    store.getState().jobs.isLoadingJobs = false;

    setValues({
      ...values,
      width: window.innerWidth,
      height: window.innerHeight,
    });

    window.addEventListener("resize", windowOnResizeListener);

    // Is from trending category
    if (isTrendingCategories()) {
      populateTrendingCategory().then((response) => {
        if (response) {
          // Clear job list
          props.clearJobList().then((response) => {
            // Get job list
            loadingJobs(true);
            props.updateJobAlertToggle(true, true);
          });
        }
      });
    }

    // Sync job filter keyword after navigate from job alerts page or homepage landing
    // Only trigger if not from trending category
    if (!isTrendingCategories() && store.getState().jobs.fromJobAlertsPage) {
      syncJobAlertFilter().then((response) => {
        store.getState().jobs.syncJobAlertToggle = true;
        // Clear job list
        props.clearJobList().then((response) => {
          // Get job list
          loadingJobs(true);
          props.updateJobAlertToggle(true);
        });
      });
    }
    // 2. From landing page
    else if (!isTrendingCategories() && store.getState().jobs.fromLandingPage) {
      syncJobLandingFilter().then((syncResponse) => {
        // Clear job list
        props.clearJobList().then((response) => {
          // Get job list
          loadingJobs(true);
          props.updateJobAlertToggle(true, true);
        });

        if (syncResponse?.jobListFilter?.keyword?.length > 0) {
          const keyword = syncResponse.jobListFilter?.keyword
            .toLowerCase()
            .trim();
          if (store.getState().jobs.fromHomepageTrendingSearches) {
            sendTrackingEvent({
              event: "CE_search-job-trending-standalone",
              search_term: `${keyword}`,
            });
            store.getState().jobs.fromHomepageTrendingSearches = false;
          } else if (
            store.getState().jobs.fromHomepageSearchbarTrendingSearches
          ) {
            sendTrackingEvent({
              event: "CE_search-job-trending-main",
              search_term: `${keyword}`,
            });
            store.getState().jobs.fromHomepageSearchbarTrendingSearches = false;
          } else if (store.getState().jobs.fromHomepageRecentSearches) {
            sendTrackingEvent({
              event: "CE_search-job-recent-main",
              search_term: `${keyword}`,
            });
            store.getState().jobs.fromHomepageRecentSearches = false;
          } else if (store.getState().jobs.fromHomepageSuggestedSearches) {
            sendTrackingEvent({
              event: "CE_search-job-main-suggestion",
              search_term: `${keyword}`,
            });
            store.getState().jobs.fromHomepageSuggestedSearches = false;
          } else {
            sendTrackingEvent({
              event: "CE_search-job-main",
              search_term: `${keyword}`,
            });
            store.getState().jobs.fromLandingPage = false;
          }
        }
      });
    }
    // 3. From navbar
    else if (store.getState().navbar.fromNavbarSearch) {
      syncNavbarSearch().then((syncResponse) => {
        // Clear job list
        props.clearJobList().then((response) => {
          // Get job list
          loadingJobs(true);
          props.updateJobAlertToggle(true, true);
        });

        let cKeyword;

        if (syncResponse?.jobListFilter.keyword.constructor === Array) {
          cKeyword = syncResponse.jobListFilter.keyword[0];
        } else {
          cKeyword = syncResponse.jobListFilter.keyword;
        }

        if (cKeyword.length > 0) {
          const keyword = cKeyword.toLowerCase().trim();
          if (store.getState().jobs.fromNavbarTrendingSearches) {
            sendTrackingEvent({
              event: "CE_search-job-trending-nav",
              search_term: `${keyword}`,
            });
            store.getState().jobs.fromNavbarTrendingSearches = false;
          } else if (store.getState().jobs.fromNavbarRecentSearches) {
            sendTrackingEvent({
              event: "CE_search-job-recent-nav",
              search_term: `${keyword}`,
            });
            store.getState().jobs.fromNavbarRecentSearches = false;
          } else if (store.getState().jobs.fromNavbarSuggestedSearches) {
            sendTrackingEvent({
              event: "CE_search-job-nav-suggestion",
              search_term: `${keyword}`,
            });
            store.getState().jobs.fromNavbarSuggestedSearches = false;
          } else {
            sendTrackingEvent({
              event: "CE_search-job-nav",
              search_term: `${keyword}`,
            });
            store.getState().jobs.fromNavbarSearch = false;
            store.getState().jobs.fromMobileNavbar = false;
          }
        }
      });
    }

    // 5. else clear and load jobs
    else {
      props.clearJobList().then((response) => {
        // Get job list
        loadingJobs(true);
      });
    }

    // Check if visitor job alert exists then create job alert for user
    const user = getUser();

    if (user) {
      let visitorJobAlert = getItem("visitorJobAlert");
      if (visitorJobAlert) {
        let jobAlertFilter = JSON.parse(
          JSON.stringify(store.getState().jobs.jobListFilter)
        );
        jobAlertFilter.keyword = visitorJobAlert;
        props.updateJobListFilter(jobAlertFilter).then((response) => {
          store.getState().jobs.syncJobAlertToggle = true;
          props.clearJobList().then((response) => {
            loadingJobs(true);
            props.updateJobAlertToggle(true);
          });
        });
        if (checkJobAlertExists(visitorJobAlert)) {
          triggerSnackbarFunc({
            snackbarMessage: "Job alert created.",
            severity: "success",
          });
        } else {
          let params = {
            title: visitorJobAlert,
            frequency: "daily",
            medium: "email",
          };
          props.createJobAlert(params).then((response) => {
            if (response.type == jobTypes.CREATE_JOB_ALERT_SUCCEED) {
              triggerSnackbarFunc({
                snackbarMessage: "Job alert created.",
                severity: "success",
              });

              sendTrackingEvent({
                event: "CE_create-job-alert-joblist",
                "job-alert-id": response?.jobAlert?.id,
              });
            } else {
              triggerSnackbarFunc({
                snackbarMessage: "Job alert cannot be created.",
                severity: "error",
              });
            }
            setAutoCreatedAlert(true);
            props.updateJobAlertDialog(true);
            props.updateJobAlertVisitorEmailDialog(false);
          });
        }
      }
      removeItem("visitorJobAlert");
    }

    // For visitor job alert activation and deactivation
    const url = window.location.search;
    const urlParams = new URLSearchParams(url);
    const cEmail = urlParams.get("email");
    const cVisitorAlert = urlParams.get("title");
    const cVisitorAlertID = urlParams.get("id");

    setVisitorAlert(cVisitorAlert);
    setVisitorAlertID(cVisitorAlertID);

    if (cVisitorAlert) {
      let jobAlertFilter = JSON.parse(
        JSON.stringify(store.getState().jobs.jobListFilter)
      );
      jobAlertFilter.keyword = cVisitorAlert;
      props.updateJobListFilter(jobAlertFilter).then((response) => {
        store.getState().jobs.syncJobAlertToggle = true;
        props.clearJobList().then((response) => {
          if (url.includes("?job-alert-feedback") && !user) {
            props.updateJobAlertToggle(false);
          } else {
            props.updateJobAlertToggle(true, true);
          }
        });
      });
    }

    if (url.includes("?activate-job-alert")) {
      triggerSnackbarFunc({
        snackbarMessage: "Job alert activated.",
        severity: "success",
      });
      props.updateJobAlertVisitorEmailDialog(true);
    } else if (url.includes("?deactivate-job-alert")) {
      triggerSnackbarFunc({
        snackbarMessage: "Job alert deactivated.",
        severity: "success",
      });
      props.updateJobAlertVisitorUnsubscribeDialog(true);
    } else if (url.includes("?visitor-sign-up")) {
      props.updateSignInText("Log In to Create Job Alerts");
      props.updateSignUpText("Sign Up to Manage Job Alerts");
      store.getState().user.user.email = cEmail;
      props.updateSignInModalSignUpStatus(true);
      props.updateShowSignInModalStatus(true);
    } else if (url.includes("?job-alert-feedback")) {
      triggerSnackbarFunc({
        snackbarMessage: "Thank you for your feedback.",
        severity: "success",
      });
      router.replace("/jobs");
    }

    setAutoCreatedAlert(true);

    return () => {
      store.getState().jobs.jobListFilter = {};
      window.removeEventListener("resize", windowOnResizeListener);
      props.updateJobAlertToggle(false);
    };
  }, []);

  function windowOnResizeListener() {
    setValues({
      ...values,
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  function updateJobListFilterFunction(key, value) {
    let jobFilter = JSON.parse(
      JSON.stringify(store.getState().jobs.jobListFilter)
    );

    if (key == "keyword" || key == "expectedSalary") {
      jobFilter[key] = value;
    } else if (value == -1) {
      key = getFilterKey(key);
      jobFilter[key] = [];
    } else {
      key = getFilterKey(key);
      let currentArray = jobFilter[key] ? jobFilter[key] : [];
      let index = currentArray.indexOf(value);
      if (index != -1) {
        currentArray = currentArray.filter((arrayValue) => {
          return arrayValue != value;
        });
      } else {
        currentArray.push(value);
      }
      jobFilter[key] = currentArray;
    }

    props.updateJobListFilter(jobFilter);
  }

  function getFilterKey(key) {
    switch (key) {
      case "specialization": {
        return "specialisationIds";
      }
      case "track": {
        return "trackIds";
      }
      case "state": {
        return "stateRegionNames";
      }
      case "job-type": {
        return "jobTypeIds";
      }
      case "experience": {
        return "experienceIds";
      }
    }
  }

  function syncJobLandingFilter() {
    return new Promise((resolve) => {
      if (!store.getState().jobs.fromLandingPage) {
        props.updateJobListFilter({}); // Clear filter when first come to here
        return resolve(false);
      }

      store.getState().jobs.fromLandingPage = false;

      let jobLandingFilter = JSON.parse(
        JSON.stringify(store.getState().jobs.jobListFilterLanding)
      );

      if (jobLandingFilter) {
        let jobFilter = {
          keyword: jobLandingFilter.keyword ? jobLandingFilter.keyword : "",
          trackIds: jobLandingFilter.trackIds ? jobLandingFilter.trackIds : [],
          stateRegionNames: jobLandingFilter.stateRegionNames
            ? jobLandingFilter.stateRegionNames
            : [],
          jobTypeIds: jobLandingFilter.jobTypeIds
            ? jobLandingFilter.jobTypeIds
            : [],
          experienceIds: jobLandingFilter.experienceIds
            ? jobLandingFilter.experienceIds
            : [],
          expectedSalary: jobLandingFilter.expectedSalary
            ? jobLandingFilter.expectedSalary
            : 0,
        };

        // Fetch search suggestions list
        if (jobLandingFilter.keyword) {
          let params = {
            keyword: jobLandingFilter.keyword,
          };
          props.fetchSearchSuggestions(params);
        }

        props.updateJobListFilter(jobFilter).then((response) => {
          return resolve(response);
        });
      }
    });
  }

  function syncJobAlertFilter() {
    return new Promise((resolve) => {
      if (!store.getState().jobs.fromJobAlertsPage) {
        props.updateJobListFilter({});
        return resolve(false);
      }

      store.getState().jobs.fromJobAlertsPage = false;

      let jobAlertFilter = JSON.parse(
        JSON.stringify(store.getState().jobs.jobListFilter)
      );

      let jobAlert = store.getState().jobs.jobAlert;

      if (jobAlert) {
        jobAlertFilter["keyword"] = jobAlert ? jobAlert.title : "";

        // Fetch search suggestions list
        if (jobAlert.title) {
          let params = {
            keyword: jobAlert.title,
          };
          props.fetchSearchSuggestions(params);
        }

        props.updateJobListFilter(jobAlertFilter).then((response) => {
          return resolve(response);
        });
      }
    });
  }

  function syncNavbarSearch() {
    return new Promise((resolve) => {
      if (!store.getState().navbar.fromNavbarSearch) {
        props.updateJobListFilter({});
        return resolve(false);
      }

      store.getState().navbar.fromNavbarSearch = false;

      let navbarSearchFilter = JSON.parse(
        JSON.stringify(store.getState().jobs.jobListFilter)
      );

      if (navbarSearchFilter) {
        let navbarFilter = {
          keyword: navbarSearchFilter.keyword ? navbarSearchFilter.keyword : "",
        };

        // Fetch search suggestions list
        if (navbarSearchFilter.keyword) {
          let params = {
            keyword: navbarSearchFilter.keyword,
          };
          props.fetchSearchSuggestions(params);
        }

        props.updateJobListFilter(navbarFilter).then((response) => {
          return resolve(response);
        });
      }
    });
  }

  const loadingJobs = async (refresh, origin) => {
    if (store.getState().jobs.isLoadingJobs && !refresh) return;

    // To highlight internship button filter when active
    if (
      store.getState().jobs.jobListFilter?.jobTypeIds?.length === 1 &&
      store.getState().jobs.jobListFilter?.jobTypeIds?.includes(4)
    ) {
      props.updateInternshipFilterButtonStatus(true);
    } else {
      props.updateInternshipFilterButtonStatus(false);
    }

    store.getState().jobs.isLoadingJobs = true;

    // Cancel any existing call to graphQL
    if (store.getState().axios.cancelTokens[jobTypes.FETCHING_JOBS_KEY]) {
      store
        .getState()
        .axios.cancelTokens[jobTypes.FETCHING_JOBS_KEY].cancel(
          "job list search"
        );
    }

    let currentLoadingJobsKey = new Date().getTime();
    store.getState().jobs.currentLoadingJobsKey = currentLoadingJobsKey;

    let params = formJobQueryParam(
      store.getState().jobs.jobListFilter,
      refresh
    );

    if (firstTimeMountLoadingJobs.current === false) {
      let preProcessStateRegion;

      if (
        params.stateRegions &&
        Array.isArray(params.stateRegions) &&
        params.stateRegions.join(", ").length !== 0
      ) {
        // If stateRegions is array and joined string length is not 0
        preProcessStateRegion = params.stateRegions;
      } else if (
        params.stateRegions &&
        Array.isArray(params.stateRegions) &&
        params.stateRegions.length === 0
      ) {
        // If stateRegions is array and joined string length is 0
        preProcessStateRegion = null;
      } else {
        preProcessStateRegion = null;
      }

      let reduxJobTracks = store?.getState()?.jobs?.tracks;
      let reduxJobTypes = store?.getState()?.jobs?.jobTypes;
      let reduxJobExpLevel = store?.getState()?.jobs?.experienceLevels;

      const regex = /\d+(\.\d+)?/g;
      const selectedExp =
        reduxJobExpLevel
          .filter((exp) => params?.experienceIds?.includes(parseInt(exp.id)))
          .map((match) =>
            match.title.toLowerCase().replace(/intern|fresh graduate/g, "0")
          ) ?? [];

      const cleanSelectExp = selectedExp.join().match(regex)?.sort() ?? [];

      sendTrackingEvent({
        event: "CE_apply-filter-job",
        "search-term":
          params.keyword && params.keyword !== "*" ? params.keyword : null,
        specialisation: params.trackIds ?? null,
        states: preProcessStateRegion ?? null,
        "job-type": params.jobTypeIds ?? null,
        experience: params.experienceIds ?? null,
        salary:
          params.expectedSalary &&
          Number.isInteger(params.expectedSalary) &&
          params.expectedSalary > 0
            ? calibrateSalary(params.expectedSalary)
            : null,

        // ct-specialization: {{array of string}}
        "ct-specialization":
          reduxJobTracks
            .filter((track) => params?.trackIds?.includes(parseInt(track.id)))
            .map((match) => match.slug) ?? [],

        // ct-job-types: {{array of string}}
        "ct-job-types":
          reduxJobTypes
            .filter((type) => params?.jobTypeIds?.includes(parseInt(type.id)))
            .map((match) => match.title) ?? [],

        "ct-min-exp": parseInt(cleanSelectExp[0] ?? 0) ?? 0,

        "ct-max-exp":
          parseInt(cleanSelectExp[cleanSelectExp.length - 1] ?? 0) ?? 0,
      });
    } else {
      firstTimeMountLoadingJobs.current = false;
    }

    if (refresh) {
      store.getState().jobs.currentId = "";
      props.updateJobId("");
      setCurrentPage(1);
    }

    if (refresh) {
      clearAndLoadJobs(params);
    } else {
      loadJobs(params);
    }

    // Trigger custom event for GA tracking if filters are applied
    if (origin == "job-search-filters" || origin == "nav-job-search") {
      let cKeyword;
      let cFilter = store.getState().jobs.jobListFilter;

      if (cFilter.keyword) {
        if (cFilter?.keyword.constructor === Array) {
          cKeyword = cFilter?.keyword[0]?.trim();
        } else {
          cKeyword = cFilter?.keyword?.trim();
        }
      }
    }
  };

  function loadJobs(params, ashleyToggle) {
    const pagination = {
      first: 30,
      last: null,
      startCursor: null,
      endCursor: null,
    };

    props.getJobsParams(params);

    props
      .getJobs(params, pagination)
      .then((response) => {
        if (response.type === "FETCH_JOBS_SUCCEED" && ashleyToggle) {
          triggerSnackbarFunc({
            snackbarMessage: "Your page is now reset to the default search",
            severity: "ashley",
          });
        }

        setGetJobListDone(true);

        store.getState().jobs.loadingToggleAshley = false;
        store.getState().jobs.isLoadingJobs = false;

        if (params.refresh) {
          if (store.getState().jobs.jobs.length > 0) {
            setCurrentPage(1);
          }
        }
      })
      .catch((error) => {
        store.getState().jobs.isLoadingJobs = false;
      });
  }

  function clearAndLoadJobs(params, ashley) {
    props.clearJobList().then((response) => {
      loadJobs(params, ashley);
    });
  }

  function isSignedIn(showPopUp) {
    let signedIn = checkAuthentication();

    if (!signedIn && showPopUp) {
      props.updateShowSignInModalStatus(true);
    }

    return signedIn;
  }

  function onFollowJobClicked(jobId, origin) {
    return new Promise((resolve) => {
      if (!isSignedIn(true) || isSavingJob) {
        if (!isSignedIn(false)) {
          props.updateSignInText("Log In to Save Jobs");
          props.updateSignUpText("Sign Up to Save Jobs");
        }

        return resolve(false);
      }

      if (props.job.id === jobId && props.job.bookmark) {
        unSaveJob(jobId);
        return;
      }

      if (jobId) {
        setCurrentId(jobId);
        isSavingJob = true;

        let params = {
          jobId: jobId,
          canCancel: false,
          origin: origin ? "recommended-job" : "job-list",
        };

        props
          .bookmarkJob(params)
          .then((response) => {
            isSavingJob = false;
            if (response.type == jobTypes.BOOKMARK_JOB_SUCCEED) {
              triggerSnackbarFunc({
                snackbarMessage: "Saved job successfully",
                severity: "success",
              });
            }

            return resolve(true);
          })
          .catch((error) => {
            isSavingJob = false;
            return resolve(false);
          });
      }
    });
  }

  function unSaveJob(jobId, origin) {
    return new Promise((resolve) => {
      if (!isSignedIn(true) || isSavingJob) return resolve(false);

      if (jobId) {
        setCurrentId(jobId);
        isSavingJob = true;

        let params = {
          jobId: jobId,
          canCancel: false,
          origin: origin ? "recommended-job" : "job-list",
        };
        props
          .unBookmarkJob(params)
          .then((response) => {
            isSavingJob = false;
            if (response.type == jobTypes.UNBOOKMARK_JOB_SUCCEED) {
              triggerSnackbarFunc({
                snackbarMessage: "Unsaved job successfully",
                severity: "success",
              });
            }
            return resolve(true);
          })
          .catch((error) => {
            isSavingJob = false;
            return resolve(false);
          });
      }
    });
  }

  function onApplyJobClicked(job, reduxUser) {
    if (
      (checkAuthentication() && !reduxUser?.nationality) ||
      (checkAuthentication() && !reduxUser?.mobileNumber) ||
      (checkAuthentication() && !reduxUser?.currentLocation)
    ) {
      const params = {
        showNationalityDialog: true,
      };

      dispatch(updateNationalityDialog(params));
    } else {
      setAppliedJob(job);

      return new Promise((resolve) => {
        if (!isSignedIn(true) || isApplyingJob) {
          if (!isSignedIn(false)) {
            props.updateSignInText("Log In to Apply for Jobs");
            props.updateSignUpText("Sign Up to Apply for Jobs");
          }

          sendTrackingEvent({
            event: "applied-job",
            "job-title": job.title,
            resume: false,
            logged_in: false,
            scraped: job.scraped !== "",
            "job-bucket-id": null,
            "job-id": job?.id,
          });
          return resolve(false);
        }

        const user = getUser();

        // Do not send in application if user has no resume/rejected
        if (
          !user?.resume ||
          user?.state == "rejected" ||
          user?.state == "no_resume"
        ) {
          if (!user?.resume || user?.state == "no_resume") {
            dispatch(updateCreateResumePopupStatus(true));
          } else if (user?.state == "rejected") {
            dispatch(updateRejectedResumePopupStatus(true));
          }

          sendTrackingEvent({
            event: "applied-job",
            "job-title": job.title,
            resume: false,
            logged_in: true,
            scraped: job.scraped !== "",
            "job-bucket-id": null,
            "job-id": job?.id,
          });

          return resolve(false);
        }

        let jobId = null;

        if (job) {
          if (job?.category === "scraped" || job?.category === "aggregated") {
            const cleanSalary = job?.salary
              ?.split("-")
              .map((item) => item.replaceAll("RM", ""));

            posthog.capture("click_apply", {
              origin: "job_list",
              job_id: job?.id,
              job_title: job?.title,
              job_type: job?.jobType,
              spotlight: job?.spotlight,
              featured: job?.boosted,
              keyword: job?.keywordHighlight,
              scraped: job?.scraped ? true : false,
              job_slot_type: job?.jobSlotType,
              track_titles: job?.tracks.map((track) => track?.title) ?? [],
              company_id: job?.company?.id,
              company_name: job?.company?.name,
              location: job?.location,
              state_region: job?.stateRegion,
              hide_salary:
                !job?.salary ||
                job?.salary.toString().toLowerCase().includes("undisclosed")
                  ? true
                  : false,
              career_level: job?.careerLevel,
              min_years_experience: job?.minYearsExperience,
              max_years_experience: job?.maxYearsExperience,
              min_salary_range: parseInt(cleanSalary[0]) ?? 0,
              max_salary_range:
                parseInt(cleanSalary[cleanSalary.length - 1]) ?? 0,
            });

            window.open(job.externalJobUrl, "_blank");
          } else {
            if (job.id) {
              setCurrentId(job.id);
              jobId = job.id;
            } else {
              setCurrentId(job);
              jobId = job;
            }
            isApplyingJob = true;

            let params = {
              jobId: jobId,
              canCancel: false,
            };

            props
              .applyJob(params)
              .then((response) => {
                isApplyingJob = false;
                if (response.type == jobTypes.APPLY_JOB_SUCCEED) {
                  const cleanSalary = job?.salary
                    ?.split("-")
                    .map((item) => item.replaceAll("RM", ""));

                  posthog.capture("click_apply", {
                    origin: "job_list",
                    job_id: job?.id,
                    job_title: job?.title,
                    job_type: job?.jobType,
                    spotlight: job?.spotlight,
                    featured: job?.boosted,
                    keyword: job?.keywordHighlight,
                    scraped: job?.scraped ? true : false,
                    job_slot_type: job?.jobSlotType,
                    track_titles:
                      job?.tracks.map((track) => track?.title) ?? [],
                    company_id: job?.company?.id,
                    company_name: job?.company?.name,
                    location: job?.location,
                    state_region: job?.stateRegion,
                    hide_salary:
                      !job?.salary ||
                      job?.salary
                        .toString()
                        .toLowerCase()
                        .includes("undisclosed")
                        ? true
                        : false,
                    career_level: job?.careerLevel,
                    min_years_experience: job?.minYearsExperience,
                    max_years_experience: job?.maxYearsExperience,
                    min_salary_range: parseInt(cleanSalary[0]) ?? 0,
                    max_salary_range:
                      parseInt(cleanSalary[cleanSalary.length - 1]) ?? 0,
                  });

                  sendTrackingEvent({
                    event: "applied-job",
                    "job-title": job.title,
                    resume: true,
                    logged_in: true,
                    scraped: job.scraped !== "",
                    "job-bucket-id": response?.payload?.jobBucketId ?? null,
                    job_preference_toggle: jobPreferenceToggle ? "on" : "off",
                    "job-id": job?.id,
                  }); // custom applied-job event for GTM tracking

                  if (user?.state == "in_review") {
                    dispatch(updateInReviewResumePoupupStatus(true));
                  } else {
                    props.updateJobAppliedDialog(true);
                  }

                  triggerSnackbarFunc({
                    snackbarMessage: "Your application has been sent!",
                    severity: "success",
                  });

                  if (job.external_job_url || job.externalJobUrl) {
                    setTimeout(() => {
                      window.open(job.external_job_url || job.externalJobUrl);
                    }, 1500);
                  }
                }
                return resolve(true);
              })
              .catch((error) => {
                isApplyingJob = false;
                return resolve(false);
              });
          }
        }
      });
    }
  }

  function checkJobAlertExists(keyword) {
    if (!keyword) return;

    return store.getState().jobs.jobAlerts.some(function (alert) {
      if (
        alert.title.toString().toLowerCase() == keyword.toString().toLowerCase()
      ) {
        return true;
      } else {
        return false;
      }
    });
  }

  return (
    <Wrapper>
      <SharedNavbar2 page={"job-list-page"} loadingJobs={loadingJobs} />

      <JobPageContainer container alignItems="center">
        <JobSearchContainer>
          {width > 767 ? (
            <SearchBar
              page={props.page}
              updateJobListFilterFunction={updateJobListFilterFunction}
              loadingJobs={loadingJobs}
              autoCreatedAlert={autoCreatedAlert}
              setAutoCreatedAlert={setAutoCreatedAlert}
            />
          ) : null}
          <JobSearchOptions
            updateJobListFilterFunction={updateJobListFilterFunction}
            jobPreferenceToggle={jobPreferenceToggle}
            setJobPreferenceToggle={setJobPreferenceToggle}
            isSignedIn={isSignedIn}
            loadingJobs={loadingJobs}
            width={width}
          />
        </JobSearchContainer>
        <JobAlertToggle noSearchResults loadingJobs={loadingJobs} />
        <AccordionJobList
          onFollowJobClicked={onFollowJobClicked}
          unSaveJob={unSaveJob}
          currentId={currentId}
          onApplyJobClicked={onApplyJobClicked}
          jobPreferenceToggle={jobPreferenceToggle}
          job={props.job}
        />
        <CreateResumePopup />
        <RejectedResumePopup />
        <ResumeInReviewPopup job={appliedJob} />
        <JobAlertVisitorUnsubscribeDialog
          visitorAlert={visitorAlert}
          visitorAlertID={visitorAlertID}
        />
        <JobAlertDialog />
        <JobAppliedDialog
          job={appliedJob}
          onFollowJobClicked={onFollowJobClicked}
          unSaveJob={unSaveJob}
        />
        <JobAlertsCreateDialog />
        <VisitorJobAlertDialog />
        <VisitorJobAlertSuccesDialog />
      </JobPageContainer>
    </Wrapper>
  );
}

const mapStateToProps = (state) => {
  return {
    job: state.jobs.job,
    fetchingJobList: state.jobs.fetchingJobList,
    loadingToggleAshley: state.jobs.loadingToggleAshley,
    fetchingJob: state.jobs.fetchingJob,
    jobs: state.jobs.jobs,
    jobPageInfoV1: state.jobs.jobPageInfoV1,
    currentUser: state.user.currentUser,
    tracks: state.jobs.tracks,
    experienceLevels: state.jobs.experienceLevels,
    stateRegions: state.jobs.stateRegions,
    jobTypes: state.jobs.jobTypes,
    internshipFilterButtonStatus: state.jobs.internshipFilterButtonStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    bookmarkCompany: bindActionCreators(bookmarkCompany, dispatch),
    bookmarkJob: bindActionCreators(bookmarkJob, dispatch),
    getJobsParams: bindActionCreators(getJobsParams, dispatch),
    applyJob: bindActionCreators(applyJob, dispatch),
    updateJobListFilter: bindActionCreators(updateJobListFilter, dispatch),
    updateSelectedJob: bindActionCreators(updateSelectedJob, dispatch),
    updateShowSignInModalStatus: bindActionCreators(
      updateShowSignInModalStatus,
      dispatch
    ),
    clearJobList: bindActionCreators(clearJobList, dispatch),
    getJobs: bindActionCreators(getJobs, dispatch),
    getJobV1: bindActionCreators(getJobV1, dispatch),
    unBookmarkCompany: bindActionCreators(unBookmarkCompany, dispatch),
    unBookmarkJob: bindActionCreators(unBookmarkJob, dispatch),
    updateJobId: bindActionCreators(updateJobId, dispatch),
    updateSignInText: bindActionCreators(updateSignInText, dispatch),
    updateSignUpText: bindActionCreators(updateSignUpText, dispatch),
    updateJobAppliedDialog: bindActionCreators(
      updateJobAppliedDialog,
      dispatch
    ),
    updateJobAlertToggle: bindActionCreators(updateJobAlertToggle, dispatch),
    updateJobAlertDialog: bindActionCreators(updateJobAlertDialog, dispatch),
    createJobAlert: bindActionCreators(createJobAlert, dispatch),
    updateJobAlertVisitorEmailDialog: bindActionCreators(
      updateJobAlertVisitorEmailDialog,
      dispatch
    ),
    updateJobAlertVisitorUnsubscribeDialog: bindActionCreators(
      updateJobAlertVisitorUnsubscribeDialog,
      dispatch
    ),
    updateSignInModalSignUpStatus: bindActionCreators(
      updateSignInModalSignUpStatus,
      dispatch
    ),
    fetchSearchSuggestions: bindActionCreators(
      fetchSearchSuggestions,
      dispatch
    ),

    getJobAlerts: bindActionCreators(getJobAlerts, dispatch),
    updateInternshipFilterButtonStatus: bindActionCreators(
      updateInternshipFilterButtonStatus,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobListWrapper);
