import React, { useState, useEffect, Fragment } from "react";
import {
  CopyrightText,
  CopyrightContainerMiddle,
  VerticalSeparator,
  BlankContainer,
  CloseButton,
  CloseButtonContainer,
  CloseIconStyled,
  ArrowIconStyled,
  MoreButtonContainer,
  CopyRightContainer,
  LinkTextNoMargin,
  CollapseFooterContainer,
  DrawerStyled,
} from "./styles";
import NextLink from "next/link";
import FooterContent from "./FooterContent";

export default function SharedCollapsibleFooter() {
  let year = new Date().getFullYear();
  const [hasMounted, setHasMounted] = useState(false);
  const [open, setOpen] = useState(false);

  // Passing tracking UTM params from v3 employer landing page to v2 employer sign up page
  useEffect(() => {
    setHasMounted(true);
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      {hasMounted === true ? (
        screen.width < 768 ? (
          <FooterContent />
        ) : (
          <BlankContainer>
            <CollapseFooterContainer>
              <CopyRightContainer>
                <CopyrightText>
                  {`© Copyright 2014 - ${year} Agensi Pekerjaan Wobb Sdn. Bhd.`}
                </CopyrightText>
              </CopyRightContainer>

              <CopyrightContainerMiddle>
                <NextLink href="/community-guidelines" legacyBehavior>
                  <LinkTextNoMargin id={"footer-community-guidelines-button"}>
                    Community Guidelines
                  </LinkTextNoMargin>
                </NextLink>
                <VerticalSeparator></VerticalSeparator>
                <NextLink href="/privacy-policy" legacyBehavior>
                  <LinkTextNoMargin id={"footer-privacy-policy-button"}>
                    Privacy Policy
                  </LinkTextNoMargin>
                </NextLink>
                <VerticalSeparator></VerticalSeparator>
                <NextLink href="/terms-and-conditions" legacyBehavior>
                  <LinkTextNoMargin id={"footer-terms-and-conditions-button"}>
                    Terms & Conditions
                  </LinkTextNoMargin>
                </NextLink>
                <VerticalSeparator></VerticalSeparator>
                <NextLink href="/sitemap" legacyBehavior>
                  <LinkTextNoMargin id={"footer-sitemap-button"}>
                    Site Map
                  </LinkTextNoMargin>
                </NextLink>
                <VerticalSeparator></VerticalSeparator>
                <MoreButtonContainer>
                  <LinkTextNoMargin onClick={handleClickOpen}>
                    More
                    <ArrowIconStyled />
                  </LinkTextNoMargin>
                </MoreButtonContainer>
              </CopyrightContainerMiddle>
            </CollapseFooterContainer>
          </BlankContainer>
        )
      ) : null}

      <DrawerStyled open={open} onClose={handleClose} anchor="bottom">
        <CloseButtonContainer onClick={handleClose}>
          <CloseButton endIcon={<CloseIconStyled />}>Close</CloseButton>
        </CloseButtonContainer>
        <FooterContent />
      </DrawerStyled>
    </Fragment>
  );
}
