import SparklesIcon from "@heroicons/react/24/solid/SparklesIcon";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import { withTheme } from "@mui/styles";
import styled from "styled-components";
import Color from "../../../assets/colors";
import { FontSize } from "../../../assets/font";

// Box Components //
export const DropdownItem = styling(Box)({
  width: "100%",
  padding: "0.625rem 1rem",
  cursor: "pointer",

  ":hover": {
    background: Color.lightGrey,
  },
});

export const DropdownListContainer = styling(Box)(({ theme }) => ({
  zIndex: "1",
  maxHeight: "16rem",
  width: "100%",
  backgroundColor: Color.white,
  borderRadius: "0.5rem",
  position: "absolute",
  top: "5.5rem",
  left: "0",
  overflow: "auto",
  boxShadow: "0.25rem 0.25rem 1.25rem rgba(0, 0, 0, 0.16)",

  [theme.breakpoints.up("desktopL")]: {
    top: "90px",
  },

  [theme.breakpoints.down("tablet")]: {
    top: "90px",
  },
}));

// Dialog Components //
export const DialogStyled = withTheme(styled(Dialog)`
  && {
    .MuiDialog-container .MuiPaper-root {
      border-radius: 20px;
      min-width: 640px;

      ${(props) => props.theme.breakpoints.down("tablet")} {
        min-width: 90%;
      }
    }
  }
`);

export const DialogContentStyled = withTheme(styled(DialogContent)`
  && {
    padding: 60px 30px 40px 30px;
    width: 100%;
  }
`);

// Grid Components //
export const CloseButtonContainer = styled(Grid)`
  && {
    position: absolute;
    right: 25px;
    top: 10px;
  }
`;

export const ActionsContainer = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const LabelContainer = withTheme(styled(Grid)`
  && {
    position: relative;
    margin-bottom: ${(props) => (props.$jobAlertTip ? "40px" : "0px")};
    background: ${(props) =>
      props.$jobAlertTip ? Color.lightHiredlyPurple : Color.white};
    border-radius: ${(props) => (props.$jobAlertTip ? "24px" : "0px")};
    padding: ${(props) => (props.$jobAlertTip ? "15px" : "0px")};

    ${(props) => props.theme.breakpoints.down("tablet")} {
      padding: ${(props) => (props.$jobAlertTip ? "8px 10px" : "0px")};
      border-radius: ${(props) => (props.$jobAlertTip ? "50px" : "0px")};
      margin-bottom: ${(props) => (props.$jobAlertTip ? "24px" : "0px")};
    }
  }
`);

export const ButtonContainer = styled(Grid)`
  && {
    align-items: center;
    justify-content: center;
    width: 100%;
    display: flex;
    padding-top: 30px;
  }
`;

// Typography Components //
export const DropdownText = styling("span")(({ theme }) => ({
  color: Color.black,
  fontSize: "0.875rem",
}));

export const TitleStyled = withTheme(styled(Typography)`
  && {
    font-size: ${FontSize.desktopHeadline2};
    font-weight: ${FontSize.bold};
    text-align: center;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      font-size: ${FontSize.desktopHeadline};
      text-align: left;
    }
  }
`);

export const SubtitleStyled = withTheme(styled(Typography)`
  && {
    font-size: ${FontSize.desktopBody};
    text-align: center;
    margin-top: 10px;
    margin-bottom: 40px;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      text-align: left;
      margin-bottom: 24px;
    }
  }
`);

export const LabelStyled = withTheme(styled(Typography)`
  && {
    font-size: ${FontSize.desktopBody};
    margin-bottom: ${(props) => (props.$jobAlertTip ? "0px" : "5px")};
    display: ${(props) => (props.$jobAlertTip ? "flex" : null)};
    align-items: ${(props) => (props.$jobAlertTip ? "center" : null)};

    ${(props) => props.theme.breakpoints.down("tablet")} {
      font-size: ${(props) =>
        props.$jobAlertTip ? FontSize.desktopCaption : FontSize.desktopBody};
    }
  }
`);

// Button Components //
export const LeftButton = styling(Button)((props) => ({
  borderRadius: "10px 0px 0px 10px",
  borderWidth: "1px",
  borderTop: "none",
  borderBottom: "none",
  borderColor: Color.grey,
  backgroundColor: props.$selected ? Color.hiredlyPurple : Color.lightGrey,
  border: "1px",
  width: `calc(100% / 3)`,
  fontSize: FontSize.desktopBody,
  color: props.$selected ? Color.white : Color.black,
  padding: "10px 0",
  textTransform: "capitalize",

  [props.theme.breakpoints.down("tablet")]: {
    width: `calc(100% / 2)`,
    fontSize: "12px",
    height: "48px",
    lineHeight: "1.3",
  },

  "&: hover": {
    backgroundColor: props.$selected ? Color.hiredlyPurple : Color.lightGrey,
    border: "none",
  },
}));

export const CenterButton = styling(Button)((props) => ({
  borderColor: Color.grey,
  borderWidth: "1px",
  borderTop: "none",
  borderBottom: "none",
  borderRight: "none",
  backgroundColor: props.$selected ? Color.hiredlyPurple : Color.lightGrey,
  width: `calc(100% / 3)`,
  fontSize: FontSize.desktopBody,
  color: props.$selected ? Color.white : Color.black,
  padding: "10px 0",
  textTransform: "capitalize",

  [props.theme.breakpoints.down("mobileL")]: {
    fontSize: "12px",
    height: "48px",
    lineHeight: "1.3",
  },

  "&: hover": {
    backgroundColor: props.$selected ? Color.hiredlyPurple : Color.lightGrey,
    borderTop: "none",
    borderBottom: "none",
    borderRight: "none",
    borderColor: Color.grey,
    borderWidth: "1px",
  },
}));

export const RightButton = styling(Button)((props) => ({
  borderRadius: "10px 10px 10px 0",
  borderWidth: "1px",
  borderTop: "none",
  borderBottom: "none",
  borderRight: "none",
  borderColor: Color.grey,
  backgroundColor: props.$selected ? Color.hiredlyPurple : Color.lightGrey,
  width: `calc(100% / 3)`,
  fontSize: FontSize.desktopBody,
  color: props.$selected ? Color.white : Color.black,
  padding: "10px 0",
  textTransform: "capitalize",
  lineHeight: "1.2",

  [props.theme.breakpoints.down("tablet")]: {
    width: `calc(100% / 2)`,
    fontSize: "12px",
    height: "48px",
    lineHeight: "1.3",
  },

  "&: hover": {
    backgroundColor: props.$selected ? Color.hiredlyPurple : Color.lightGrey,
    borderTop: "none",
    borderBottom: "none",
    borderRight: "none",
    borderWidth: "1px",
    borderColor: Color.grey,
  },
}));

export const ButtonGroupStyled = withTheme(styled(ButtonGroup)`
  && {
    margin-top: 10px;
    width: 100%;
    margin-bottom: 16px;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      margin-bottom: ${(props) => (props.$alertVia ? "0px" : "16px")};
    }
  }
`);

// Misc. Components
export const CloseIconButton = styled(IconButton)`
  && {
    width: 40px;
    height: 40px;
  }
`;

export const CloseIconStyled = styled(CloseIcon)`
  && {
    color: ${Color.black};
    font-size: 30px;
  }
`;

export const TextFieldStyled = styling(TextField)((props) => ({
  display: "flex",
  maxWidth: "100%",
  marginBottom: "40px",

  "& .MuiOutlinedInput-root": {
    paddingLeft: "20px",
    "& fieldset": {
      borderColor: Color.grey,
      borderRadius: "8px",
      borderWidth: "1.5px",
    },
    "&:hover fieldset": {
      borderColor: Color.grey,
    },
    "&.Mui-focused fieldset": {
      borderColor: Color.hiredlyPurple,
      borderWidth: "1px",
      backgroundColor: "transparent",
    },
  },

  [props.theme.breakpoints.down("tablet")]: {
    marginBottom: "24px",
  },
}));

export const SparklesStyled = styling(SparklesIcon)((props) => ({
  color: Color.hiredlyPurple,
  width: "30px",
  height: "30px",
  marginRight: "10px",
}));

export const SingleRadioStyled = styling(Radio)((props) => ({
  paddingBottom: "0px",
}));

export const FormControlStyled = styling(FormControl)((props) => ({
  //
}));

export const RadioGroupStyled = styling(RadioGroup)((props) => ({
  //
}));

export const FormControlLabelStyled = styling(FormControlLabel)((props) => ({
  "& .MuiFormControlLabel-label": {
    fontWeight: "500",
    fontSize: FontSize.desktopBody,
  },
}));

export const ContainerStyled = styling(Box)((props) => ({
  width: "80%",

  [props.theme.breakpoints.up("tablet")]: {
    width: "100%",
  },
}));
