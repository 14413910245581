import { FontSize } from "../../../assets/font";
import Color from "../../../assets/colors";
import styled from "styled-components";
import { styled as styling } from "@mui/material/styles";
import withTheme from "@mui/styles/withTheme";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import EmailIcon from "@mui/icons-material/Email";
import CloseIcon from "@mui/icons-material/Close";

// Dialog Components //
export const DialogStyled = withTheme(styled(Dialog)`
  && {
    .MuiDialog-container .MuiPaper-root {
      border-radius: 20px;
      min-width: 40%;

      ${(props) => props.theme.breakpoints.down("mobileL")} {
        padding: 10px;
      }
    }
  }
`);
export const DialogContentStyled = withTheme(styled(DialogContent)`
  && {
    padding: 0 50px 20px 50px;
    width: 100%;

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      min-width: 100%;
      padding: 10px 20px;
    }
  }
`);

// Grid Components //
export const HeaderContainer = withTheme(styled(Grid)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    flex-direction: column;

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      margin-bottom: 15px;
    }
  }
`);
export const TitleContainer = withTheme(styled(Grid)`
  && {
    ${(props) => props.theme.breakpoints.down("mobileL")} {
      width: 70%;
      margin-bottom: ${(props) => (props.$margin_bottom ? "15px" : "")};
    }
  }
`);

export const CloseButtonContainer = styling(Grid)({
  display: "flex",
  justifyContent: "flex-end",
});

export const DescriptionContainer = styling(Grid)({
  marginBottom: "30px",
});

export const ReplaceAlertContainer = styling(Grid)({
  display: "flex",
  justifyContent: "space-between",
  margin: "20px 0",
  alignItems: "center",
  width: "100%",
});

export const FrequencyContainer = styling(Grid)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  marginTop: "25px",
});

export const InformationContainer = styling(Grid)({
  borderRadius: "20px",
  backgroundColor: "rgba(239, 239, 253, 1)",
  display: "flex",
  justifyContent: "space-evenly",
  padding: "10px",
  width: "70%",
  maxWidth: "100%",
  margin: "25px 0",
  alignItems: "center",
});

// Typography Components //
export const TitleStyled = styled(Typography)`
  && {
    font-size: ${FontSize.desktopBody};
    font-weight: ${(props) => (props.$bold ? `${FontSize.bold}` : "")};
    text-align: center;
  }
`;

export const JobAlertStyled = styling(Typography)({
  fontSize: FontSize.desktopBody,
  fontWeight: FontSize.bold,
  color: "#777272",
});

export const SubtitleStyled = styled(Typography)`
  && {
    font-size: ${FontSize.desktopCaption};
    font-weight: ${(props) => (props.$bold ? `${FontSize.bold}` : "")};
    text-align: center;
  }
`;

// Icon Components //
export const CheckIconStyled = withTheme(styled(CheckIcon)`
  && {
    width: 42px;
    height: 42px;
    color: ${Color.white};

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      width: 38px;
      height: 38px;
    }
  }
`);

export const EmailIconStyled = styling(EmailIcon)({
  width: "25px",
  height: "25px",
  color: Color.hiredlyPurple,
});

export const CloseIconStyled = styling(CloseIcon)({
  fontSize: FontSize.desktopDisplay2,
});

// Button Components //
export const LeftButton = styling(Button)((props) => ({
  borderRadius: "30px 0px 0px 30px",
  borderWidth: "1px",
  borderTop: "none",
  borderBottom: "none",
  borderColor: Color.grey,
  backgroundColor: props.$selected ? Color.hiredlyPurple : Color.lightGrey,
  border: "1px",
  width: `calc(100% / 3)`,
  fontSize: FontSize.desktopBody,
  color: props.$selected ? Color.white : Color.black,
  padding: "10px 0",
  textTransform: "capitalize",

  [props.theme.breakpoints.down("mobileL")]: {
    fontSize: "12px",
    height: "48px",
    lineHeight: "1.3",
  },

  "&: hover": {
    backgroundColor: props.$selected ? Color.hiredlyPurple : Color.lightGrey,
    border: "none",
  },
}));

export const RightButton = styling(Button)((props) => ({
  borderRadius: "30px 30px 30px 0",
  borderWidth: "1px",
  borderTop: "none",
  borderBottom: "none",
  borderColor: Color.grey,
  backgroundColor: props.$selected ? Color.hiredlyPurple : Color.lightGrey,
  width: `calc(100% / 3)`,
  fontSize: FontSize.desktopBody,
  color: props.$selected ? Color.white : Color.black,
  padding: "10px 0",
  textTransform: "capitalize",

  [props.theme.breakpoints.down("mobileL")]: {
    fontSize: "12px",
    height: "48px",
    lineHeight: "1.3",
  },

  "&: hover": {
    backgroundColor: props.$selected ? Color.hiredlyPurple : Color.lightGrey,
    borderTop: "none",
    borderBottom: "none",
    borderRight: "none",
    borderWidth: "1px",
    borderColor: Color.grey,
  },
}));

export const ButtonGroupStyled = styling(ButtonGroup)({
  marginTop: "10px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

// Misc. Components //
export const CheckIconContainer = withTheme(styled.div`
  && {
    width: 60px;
    height: 60px;
    border-radius: 50px;
    background-color: ${Color.chipGreen};
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 20px 0;

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      width: 48px;
      height: 48px;
    }
  }
`);

export const CloseIconButton = styling(IconButton)({
  width: "40px",
  height: "40px",
  transform: "translateY(20px) translateX(-20px)",
});

export const LinkStyled = styling("a")({
  fontWeight: FontSize.bold,
  color: Color.hiredlyPurple,
  textDecoration: "none",
  marginTop: "20px",
  display: "block",
});
