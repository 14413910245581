import { tooltipClasses } from "@mui/material/Tooltip";
import { decode } from "html-entities";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Color from "../../../assets/colors";
import * as config from "../../../config/config";
import { getMotivationalQuote } from "../../../redux/actions/job_action";
import AiJobSummaryError from "../AiJobSummaryError/AiJobSummaryError";
import { AiJobSummarySkeleton } from "../Skeleton/Skeleton";
import {
  AiSummaryActionButtons,
  AiSummaryCompanyText,
  AiSummaryContainer,
  AiSummaryHeader,
  AiSummaryJobText,
  AiSummaryText,
  AiSummaryTextContainer,
  AiSummaryTitle,
  AiSummaryTitleContainer,
  AiSummaryTitleText,
  ApplyJobButton,
  ArrowIconStyled,
  AshleyImage,
  BetaText,
  CloseButton,
  CustomTooltip,
  DivStyled,
  SparklesIconStyled,
  SpeechTail,
  XMarkIconStyled,
} from "./styles";

function AiJobSummary(props) {
  const {
    setShowAiSummary,
    showAiSummary,
    origin,
    onApplyJobClicked,
    jobIndex,
    scrollToComponent,
  } = props;
  const currentJob = useSelector((state) => state.jobs?.job);
  const fetchingJob = useSelector((state) => state.jobs?.fetchingJob);
  const applyingJob = useSelector((state) => state.jobs?.applyingJob);
  const reduxUser = useSelector((state) => state.user?.user);

  const [quote, setQuote] = useState("");

  const dispatch = useDispatch();

  const showMotivateQuote = useFeatureFlagEnabled(
    "motivational-quote-ashley-hover"
  );

  function handleClose(event) {
    event.stopPropagation();
    setShowAiSummary(!showAiSummary);
  }

  // To convert the hexadecimal characters and HTML entities from backend to string format
  function convertHexAndEntitiesToString(gptSummary) {
    let regexHex = /&#x([0-9a-fA-F]+);/g;

    let result = gptSummary.replace(regexHex, function (match, hexValue) {
      let char = String.fromCharCode(parseInt(hexValue, 16));
      return char;
    });

    return decode(result);
  }

  async function getQuote() {
    const quotesArr = await dispatch(getMotivationalQuote());

    const randomisedQuote =
      Array.isArray(quotesArr) && quotesArr.length > 0
        ? quotesArr[Math.floor(Math.random() * (quotesArr?.length ?? 0 + 1))]
            ?.attributes?.quote ?? "You got this! Good luck!"
        : "You got this! Good luck!";

    setQuote(randomisedQuote);
  }

  useEffect(() => {
    getQuote();
  }, [currentJob?.id]);

  return (
    <Fragment>
      {fetchingJob ? (
        <AiJobSummarySkeleton />
      ) : !currentJob?.gptSummary ? (
        <AiJobSummaryError />
      ) : (
        <AiSummaryContainer
          $origin={origin}
          onClick={() => scrollToComponent(jobIndex)}
        >
          <AiSummaryHeader $origin={origin}>
            <AiSummaryJobText>
              {currentJob?.title} @{" "}
              <AiSummaryCompanyText
                href={`/companies/${currentJob?.company?.slug}`}
                aria-disabled={currentJob?.company?.hide ? true : false}
                target="_blank"
                rel="noopener noreferrer"
              >
                {currentJob?.company?.name}
              </AiSummaryCompanyText>
            </AiSummaryJobText>
            <CloseButton onClick={handleClose}>
              <XMarkIconStyled />
            </CloseButton>
          </AiSummaryHeader>

          <AiSummaryTitleContainer>
            <AshleyImage
              src={config.assetDomain + "/images/hiredly/ashley-image.svg"}
              alt="ashley"
            />
            <AiSummaryTitle>
              <SparklesIconStyled />
              <AiSummaryTitleText>AI-generated summary</AiSummaryTitleText>
              <BetaText>beta</BetaText>
            </AiSummaryTitle>
          </AiSummaryTitleContainer>

          <AiSummaryTextContainer>
            <SpeechTail
              src={config.assetDomain + "/images/hiredly/speech-tail-bg.svg"}
              alt="speech tail"
            />
            <AiSummaryText>
              {convertHexAndEntitiesToString(currentJob?.gptSummary)}
            </AiSummaryText>
          </AiSummaryTextContainer>

          <AiSummaryActionButtons $origin={origin}>
            <CustomTooltip
              followCursor
              sx={{
                [`& .${tooltipClasses.tooltip}`]: {
                  border: showMotivateQuote ? "3px solid black" : "0px",
                },
              }}
              title={
                showMotivateQuote ? (
                  <DivStyled
                    container
                    alignItems={"center"}
                    flexWrap={"nowrap"}
                    overflow={"hidden"}
                  >
                    <DivStyled
                      width={"36px"}
                      height={"35px"}
                      minWidth={"36px"}
                      overflow={"hidden"}
                      borderRight={"2px solid black"}
                      sx={{
                        backgroundColor: Color.lightHiredlyPurple,
                        borderTopLeftRadius: "30px",
                        borderBottomLeftRadius: "30px",
                      }}
                      container
                      justifyContent={"center"}
                    >
                      <img
                        src="https://assets.hiredly.com/images/shared/icons/ashley-avatar.png"
                        width={"35px"}
                        height={"35px"}
                        alt="ashley-avatar-image"
                        style={{
                          transform:
                            "translateY(7px) scale(1.5) translateX(2px)",
                        }}
                      />
                    </DivStyled>
                    <span style={{ padding: "0 5px" }}>{quote}</span>
                  </DivStyled>
                ) : null
              }
            >
              <ApplyJobButton
                disabled={applyingJob || currentJob.haveApplied}
                onClick={() => onApplyJobClicked(currentJob, reduxUser)}
                variant="contained"
              >
                {currentJob.haveApplied ? (
                  "applied"
                ) : currentJob?.category === "scraped" ||
                  currentJob?.category === "aggregated" ? ( // Later need to put checking for external job
                  <Fragment>
                    {"Apply"}
                    <ArrowIconStyled />
                  </Fragment>
                ) : (
                  "apply"
                )}
              </ApplyJobButton>
            </CustomTooltip>
          </AiSummaryActionButtons>
        </AiSummaryContainer>
      )}
    </Fragment>
  );
}

export default AiJobSummary;
