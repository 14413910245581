import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { updateShowJobAlertSuccessDialog } from "../../../../redux/actions/job_action";
import {
  updateShowSignInModalStatus,
  updateSignInModalSignUpStatus,
} from "../../../../redux/actions/navbar_action";
import { getUser } from "../../../../redux/actions/user_action";
import { store } from "../../../../redux/stores/store";
import {
  ButtonStyled,
  CheckCircleFilledIcon,
  CloseIconStyled,
  DialogContentStyled,
  DialogStyled,
  DialogTitleStyled,
  HyperlinkStyled,
  IconButtonStyled,
  TypographyStyled,
} from "../styles";

function VisitorJobAlertSuccesDialog(props) {
  const { origin, jobAlertTitle } = props;
  const router = useRouter();
  const openDialog = useSelector((state) => state.jobs?.visitorJobAlertSuccess);
  const user = getUser();
  const dispatch = useDispatch();

  function handleCloseDialog() {
    dispatch(updateShowJobAlertSuccessDialog(false));
  }

  function handleRedirectToJobListingPage() {
    dispatch(updateShowJobAlertSuccessDialog(false));

    store.getState().jobs.jobListFilterLanding["keyword"] = jobAlertTitle;

    store.getState().jobs.fromLandingPage = true;

    router.push({
      pathname: "/jobs",
    });
  }

  return (
    <DialogStyled
      open={openDialog ? true : false}
      PaperProps={{ style: { borderRadius: "1.25rem" } }}
    >
      <DialogTitleStyled>
        <IconButtonStyled onClick={handleCloseDialog}>
          <CloseIconStyled />
        </IconButtonStyled>
      </DialogTitleStyled>
      <DialogContentStyled>
        <TypographyStyled variant="h6">Job alert created</TypographyStyled>
        <CheckCircleFilledIcon />
        <TypographyStyled variant="subtitle1" $subtitle>
          We will notify you via email when there’s new job available.
        </TypographyStyled>
        {origin === "job-alert-landing" ? (
          <ButtonStyled onClick={handleRedirectToJobListingPage}>
            View Jobs
          </ButtonStyled>
        ) : (
          <ButtonStyled onClick={handleCloseDialog}>Done</ButtonStyled>
        )}
        {!user ? (
          <TypographyStyled variant="body2" $subtitle $register>
            <HyperlinkStyled
              onClick={() => {
                handleCloseDialog();
                dispatch(updateSignInModalSignUpStatus(true));
                dispatch(updateShowSignInModalStatus(true));
              }}
            >
              Register an account
            </HyperlinkStyled>{" "}
            with us to modify your job alert.
          </TypographyStyled>
        ) : null}
      </DialogContentStyled>
    </DialogStyled>
  );
}

export default VisitorJobAlertSuccesDialog;
