import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";
import Link from "next/link";

// Link Component //
export const LinkStyled = styling(Link)((props) => ({
  color: Color.hiredlyPurple,
  fontWeight: "700",
}));

// Box Components //
export const AiSummaryErrorWrapper = styling(Box)((props) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "22.5rem",
  height: "32.25rem",
  position: "sticky",
  top: 100,
  border: `2px dashed ${Color.warn}`,
  borderRadius: "1.25rem",
  padding: "2rem",

  [props.theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));

export const AiErrorImageBox = styling(Box)({
  //
});

export const AiSummaryErrorBox = styling(Box)({
  position: "relative",
});

// Typography Component //
export const AiSummaryErrorText = styling(Typography)({
  textAlign: "center",
});

// Image Component //
export const AiErrorImage = styling("img")({
  width: "200px",
});
