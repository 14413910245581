import { useMediaQuery } from "@mui/material";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as config from "../../../config/config";
import { formJobQueryParam } from "../../../functions/job";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import { getJobs } from "../../../redux/actions/job_action";
import { store } from "../../../redux/stores/store";
import SharedNationalityDialog from "../../shared/SharedNationalityDialog/SharedNationalityDialog";
import AccordionJobCard from "../AccordionJobCard/AccordionJobCard";
import AiJobSummary from "../AiJobSummary/AiJobSummary";
import JobAlertToggle from "../JobAlertToggle/JobAlertToggle";
import MobileJobCard from "../MobileJobCard/MobileJobCard";
import {
  AccordionJobListSkeleton,
  AiJobSummaryEmptySkeleton,
  PaginationSkeleton,
} from "../Skeleton/Skeleton";
import {
  AiArrowImage,
  AiFileImage,
  AiFileImageBox,
  AiSummaryButton,
  AiSummaryEmptyBox,
  AiSummaryEmptyText,
  AiSummaryWrapper,
  ChevronLeftIconStyled,
  ChevronRightIconStyled,
  DocumentTextIconStyled,
  EmptyContainer,
  IconButtonStyled,
  JobCardContainer,
  JobListContainer,
  OuterContainer,
  PageNumber,
  PaginationNumberBox,
} from "./styles";

function AccordionJobList(props) {
  const {
    onFollowJobClicked,
    unSaveJob,
    currentId,
    onApplyJobClicked,
    jobPreferenceToggle,
    job,
  } = props;

  const dispatch = useDispatch();
  const jobs = useSelector((state) => state.jobs.jobs);

  const fetchingJobs = useSelector((state) => state.jobs.fetchingAllJobs);

  const jobPageInfo = useSelector((state) => state.jobs.jobPageInfoV1);
  const currentPage = useSelector((state) => state.jobs.jobCurrentPage);

  const [showAiSummary, setShowAiSummary] = useState(false);
  const [jobIndex, setJobIndex] = useState("");

  const [firstAlertBannerVisible, setFirstAlertBannerVisible] = useState(true);
  const [secondAlertBannerVisible, setSecondAlertBannerVisible] =
    useState(true);
  const [thirdAlertBannerVisible, setThirdAlertBannerVisible] = useState(true);

  const isMobile = useMediaQuery("(max-width: 767px)");

  function jobListNextPage() {
    const pagination = {
      first: 30,
      last: null,
      startCursor: null,
      endCursor: jobPageInfo?.endCursor,
    };

    sendTrackingEvent({
      event: "CE_clicked-jobs-page",
      "page-number": currentPage,
      action: "next",
    });

    const jobsFilter = { ...store.getState().jobs.jobListFilter };
    const jobsParams = {
      ...formJobQueryParam(jobsFilter, false),
      excludeJobCount: true,
    };

    dispatch(getJobs(jobsParams, pagination, true, false));
    setShowAiSummary(false);
    window.scrollTo(0, 0);
  }

  function jobListPreviousPage() {
    const pagination = {
      first: null,
      last: 30,
      startCursor: jobPageInfo?.startCursor,
      endCursor: null,
    };

    sendTrackingEvent({
      event: "CE_clicked-jobs-page",
      "page-number": currentPage,
      action: "previous",
    });

    const jobsFilter = { ...store.getState().jobs.jobListFilter };
    const jobsParams = {
      ...formJobQueryParam(jobsFilter, false),
      excludeJobCount: true,
    };

    dispatch(getJobs(jobsParams, pagination, false, true));
    setShowAiSummary(false);
    window.scrollTo(0, 0);
  }

  const scrollToComponent = (index) => {
    const componentRef = document.getElementById(index);
    var headerOffset = 103;
    var elementPosition = componentRef.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  return (
    <OuterContainer>
      <SharedNationalityDialog
        onApplyJobClicked={onApplyJobClicked}
        job={job}
      />
      <JobListContainer>
        {isMobile ? (
          fetchingJobs ? (
            Array.from({ length: 10 }).map((_, index) => {
              return <PaginationSkeleton key={index} />;
            })
          ) : (
            <>
              {firstAlertBannerVisible &&
              fetchingJobs === false &&
              jobs?.length > 0 ? (
                <JobAlertToggle
                  jobAlertBannerId={`job-alert-banner-1`}
                  setBannerVisible={setFirstAlertBannerVisible}
                />
              ) : null}
              {jobs.map((job, index) => {
                return (
                  <>
                    {index === 8 &&
                    secondAlertBannerVisible &&
                    fetchingJobs === false ? (
                      <JobAlertToggle
                        jobAlertBannerId={`job-alert-banner-${index}`}
                        setBannerVisible={setSecondAlertBannerVisible}
                      />
                    ) : index === 16 &&
                      thirdAlertBannerVisible &&
                      fetchingJobs === false ? (
                      <JobAlertToggle
                        jobAlertBannerId={`job-alert-banner-${index}`}
                        setBannerVisible={setThirdAlertBannerVisible}
                      />
                    ) : null}
                    <JobCardContainer>
                      <MobileJobCard
                        job={job}
                        onApplyJobClicked={onApplyJobClicked}
                        onFollowJobClicked={onFollowJobClicked}
                        unSaveJob={unSaveJob}
                        currentId={currentId}
                        jobPreferenceToggle={jobPreferenceToggle}
                      />
                    </JobCardContainer>
                  </>
                );
              })}
              {fetchingJobs
                ? null
                : jobs?.length > 0 && (
                    <EmptyContainer
                      sx={{ width: "100%", margin: "25px 0" }}
                      container
                      direction="row"
                      justifyContent="center"
                    >
                      {jobPageInfo?.hasPreviousPage ? (
                        <Fragment>
                          <IconButtonStyled
                            $margin_right="true"
                            $pagination="true"
                            disableRipple
                            onClick={jobListPreviousPage}
                            disabled={!jobPageInfo?.hasPreviousPage}
                          >
                            <ChevronLeftIconStyled />
                          </IconButtonStyled>
                          <PaginationNumberBox $non_focus="true">
                            <PageNumber variant="subtitle2">
                              {currentPage - 1}
                            </PageNumber>
                          </PaginationNumberBox>
                        </Fragment>
                      ) : null}
                      <PaginationNumberBox $margin="true">
                        <PageNumber variant="subtitle2">
                          {currentPage}
                        </PageNumber>
                      </PaginationNumberBox>

                      {jobPageInfo?.hasNextPage && (
                        <Fragment>
                          <PaginationNumberBox $non_focus="true">
                            <PageNumber variant="subtitle2">
                              {currentPage + 1}
                            </PageNumber>
                          </PaginationNumberBox>
                          <IconButtonStyled
                            $margin_left="true"
                            $pagination="true"
                            disableRipple
                            onClick={jobListNextPage}
                            disabled={!jobPageInfo?.hasNextPage}
                          >
                            <ChevronRightIconStyled />
                          </IconButtonStyled>
                        </Fragment>
                      )}
                    </EmptyContainer>
                  )}
            </>
          )
        ) : fetchingJobs ? (
          Array.from({ length: 10 }).map((item, index) => {
            return <AccordionJobListSkeleton />;
          })
        ) : (
          <>
            {firstAlertBannerVisible &&
            fetchingJobs === false &&
            jobs?.length > 0 ? (
              <JobAlertToggle
                jobAlertBannerId={`job-alert-banner-1`}
                setBannerVisible={setFirstAlertBannerVisible}
              />
            ) : null}
            {jobs.map((job, index) => {
              return (
                <>
                  {index === 8 &&
                  secondAlertBannerVisible &&
                  fetchingJobs === false ? (
                    <JobAlertToggle
                      jobAlertBannerId={`job-alert-banner-${index}`}
                      setBannerVisible={setSecondAlertBannerVisible}
                    />
                  ) : index === 16 &&
                    thirdAlertBannerVisible &&
                    fetchingJobs === false ? (
                    <JobAlertToggle
                      jobAlertBannerId={`job-alert-banner-${index}`}
                      setBannerVisible={setThirdAlertBannerVisible}
                    />
                  ) : null}
                  <JobCardContainer id={index}>
                    <AccordionJobCard
                      job={job}
                      index={index}
                      onFollowJobClicked={onFollowJobClicked}
                      unSaveJob={unSaveJob}
                      currentId={currentId}
                      setShowAiSummary={setShowAiSummary}
                      showAiSummary={showAiSummary}
                      jobPreferenceToggle={jobPreferenceToggle}
                      setJobIndex={setJobIndex}
                      origin={"job-list"}
                    />
                  </JobCardContainer>
                </>
              );
            })}

            {fetchingJobs
              ? null
              : jobs?.length > 0 && (
                  <EmptyContainer
                    sx={{ width: "100%", marginTop: "25px" }}
                    container
                    direction="row"
                    justifyContent="center"
                  >
                    {jobPageInfo?.hasPreviousPage ? (
                      <Fragment>
                        <IconButtonStyled
                          $margin_right="true"
                          $pagination="true"
                          disableRipple
                          onClick={jobListPreviousPage}
                          disabled={!jobPageInfo?.hasPreviousPage}
                        >
                          <ChevronLeftIconStyled />
                        </IconButtonStyled>
                        <PaginationNumberBox $non_focus="true">
                          <PageNumber variant="subtitle2">
                            {currentPage - 1}
                          </PageNumber>
                        </PaginationNumberBox>
                      </Fragment>
                    ) : null}
                    <PaginationNumberBox $margin="true">
                      <PageNumber variant="subtitle2">{currentPage}</PageNumber>
                    </PaginationNumberBox>

                    {jobPageInfo?.hasNextPage && (
                      <Fragment>
                        <PaginationNumberBox $non_focus="true">
                          <PageNumber variant="subtitle2">
                            {currentPage + 1}
                          </PageNumber>
                        </PaginationNumberBox>
                        <IconButtonStyled
                          $margin_left="true"
                          $pagination="true"
                          disableRipple
                          onClick={jobListNextPage}
                          disabled={!jobPageInfo?.hasNextPage}
                        >
                          <ChevronRightIconStyled />
                        </IconButtonStyled>
                      </Fragment>
                    )}
                  </EmptyContainer>
                )}
          </>
        )}
      </JobListContainer>
      {fetchingJobs ? (
        <AiJobSummaryEmptySkeleton />
      ) : showAiSummary ? (
        <AiJobSummary
          setShowAiSummary={setShowAiSummary}
          showAiSummary={showAiSummary}
          onApplyJobClicked={onApplyJobClicked}
          jobIndex={jobIndex}
          scrollToComponent={scrollToComponent}
        />
      ) : (
        jobs?.length > 0 && (
          <AiSummaryWrapper>
            <AiFileImageBox>
              <AiFileImage
                src={config.assetDomain + "/images/hiredly/ai-files.svg"}
                alt="ai files"
              />
            </AiFileImageBox>
            <AiSummaryEmptyBox>
              <Fragment>
                <AiSummaryEmptyText>Click</AiSummaryEmptyText>
                <AiSummaryButton
                  variant="outlined"
                  endIcon={<DocumentTextIconStyled />}
                >
                  ai summary
                </AiSummaryButton>
                <AiSummaryEmptyText>to see job overview.</AiSummaryEmptyText>

                <AiArrowImage
                  src={config.assetDomain + "/images/hiredly/ai-arrow.svg"}
                  alt="arrow"
                />
              </Fragment>
            </AiSummaryEmptyBox>
          </AiSummaryWrapper>
        )
      )}
    </OuterContainer>
  );
}

export default AccordionJobList;
