import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getItem, removeItem } from "../../../helpers/data_management";
import { triggerSnackBar } from "../../../helpers/snackbar_management";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import { updateJobAlertDialog } from "../../../redux/actions/dialog_action";
import {
  replaceJobAlert,
  updateJobAlert,
} from "../../../redux/actions/job_action";
import {
  hideSnackBar,
  showSnackBar,
} from "../../../redux/actions/snackbar_action";
import { getUser } from "../../../redux/actions/user_action";
import { store } from "../../../redux/stores/store";
import * as types from "../../../redux/types/job_type";
import Button from "../../shared/SharedButton/SharedButton";
import {
  ButtonGroupStyled,
  CheckIconContainer,
  CheckIconStyled,
  CloseButtonContainer,
  CloseIconButton,
  CloseIconStyled,
  DescriptionContainer,
  DialogContentStyled,
  DialogStyled,
  EmailIconStyled,
  FrequencyContainer,
  HeaderContainer,
  InformationContainer,
  JobAlertStyled,
  LeftButton,
  LinkStyled,
  ReplaceAlertContainer,
  RightButton,
  SubtitleStyled,
  TitleContainer,
  TitleStyled,
} from "./styles";

function JobAlertDialog(props) {
  const [user, setUser] = useState(null);
  const [selectedFrequency, setSelectedFrequency] = useState("daily");
  const cUser = getUser();
  const jobAlerts = store.getState().jobs.jobAlerts;
  let currentJobAlert = props.jobAlert;

  const handleClose = () => {
    props.updateJobAlertDialog(false);
    setSelectedFrequency("daily");
  };

  const handleReplace = (jobAlert) => (event) => {
    event.preventDefault();
    event.stopPropagation();
    let params = {
      id: jobAlert.id,
      title: props.jobListFilter?.keyword,
    };
    props.replaceJobAlert(params).then((response) => {
      if (response.type === types.REPLACE_JOB_ALERT_SUCCEED) {
        let cParams = { message: "Job alert created.", severity: "success" };
        sendTrackingEvent({
          event: "replace-job-alert",
          "old-job-alert-id": jobAlert.id,
          "new-job-alert-id": response.jobAlerts[0].id,
        });
        triggerSnackBar(props.hideSnackBar, props.showSnackBar, cParams);
      } else {
        let cParams = {
          message: "Failed to replace job alert, please try again later",
          severity: "error",
        };
        triggerSnackBar(props.hideSnackBar, props.showSnackBar, cParams);
      }
    });
    // Check if visitorJobAlert exists and remove item
    let visitorJobAlert = getItem("visitorJobAlert");
    if (visitorJobAlert) {
      removeItem("visitorJobAlert");
    }
  };

  const getCurrentKeyword = () => {
    let keyword = props.jobListFilter?.keyword;

    if (keyword) {
      if (props.jobListFilter?.keyword.constructor === Array) {
        keyword = props.jobListFilter?.keyword[0];
      } else {
        keyword = props.jobListFilter?.keyword;
      }
      return keyword.trim();
    }
    return "";
  };

  useEffect(() => {
    if (cUser) {
      setUser(cUser);
    }
  }, [props.jobAlerts]);

  const handleFrequencySelected = (value) => (event) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedFrequency(value);

    let params = {
      id: currentJobAlert.id,
      frequency: value,
      medium: "email",
    };

    props.updateJobAlert(params).then((response) => {
      if (response.type === types.UPDATE_JOB_ALERT_SUCCEED) {
        let cParams = { message: "Job alert updated.", severity: "success" };
        triggerSnackBar(props.hideSnackBar, props.showSnackBar, cParams);
        sendTrackingEvent({
          event: "CE_update-job-alert-job-list",
          "job-alert-id": response.jobAlert.id,
          "job-alert-frequency": response.jobAlert.frequency,
          "job-alert-medium": response.jobAlert.medium,
        });
      } else {
        let cParams = {
          message: "Failed to update job alert, please try again later",
          severity: "error",
        };
        triggerSnackBar(props.hideSnackBar, props.showSnackBar, cParams);
      }
    });
  };

  return (
    <Fragment>
      {user && (
        <DialogStyled onClose={handleClose} open={props.showJobAlertDialog}>
          <CloseButtonContainer>
            <CloseIconButton onClick={handleClose}>
              <CloseIconStyled />
            </CloseIconButton>
          </CloseButtonContainer>

          <DialogContentStyled>
            {!props.reachedMaxJobAlerts ? (
              <HeaderContainer>
                <CheckIconContainer>
                  <CheckIconStyled />
                </CheckIconContainer>
                <TitleContainer>
                  <TitleStyled $bold>
                    Your job alert for "{getCurrentKeyword()}" is successfully
                    created!
                  </TitleStyled>
                </TitleContainer>
              </HeaderContainer>
            ) : (
              <HeaderContainer>
                <TitleContainer>
                  <TitleStyled $bold>Replace Job Alert</TitleStyled>
                </TitleContainer>
              </HeaderContainer>
            )}

            <DescriptionContainer>
              {!props.reachedMaxJobAlerts ? (
                <Fragment>
                  <SubtitleStyled>
                    You will be alerted when new jobs match your search.
                  </SubtitleStyled>

                  <FrequencyContainer>
                    <SubtitleStyled $bold>Select Frequency</SubtitleStyled>
                    <ButtonGroupStyled>
                      <LeftButton
                        $selected={selectedFrequency == "daily"}
                        onClick={handleFrequencySelected("daily")}
                      >
                        Daily
                      </LeftButton>
                      <RightButton
                        $selected={selectedFrequency == "weekly"}
                        onClick={handleFrequencySelected("weekly")}
                      >
                        Weekly
                      </RightButton>
                    </ButtonGroupStyled>

                    <InformationContainer>
                      <EmailIconStyled />
                      <SubtitleStyled>
                        {selectedFrequency == "daily"
                          ? "You will receive a job alert email every morning."
                          : "You will receive a job alert email every Monday."}
                      </SubtitleStyled>
                    </InformationContainer>

                    <Button
                      button_type={"Green"}
                      font_size={"caption"}
                      margin={"20px 0 0 0"}
                      onClick={handleClose}
                    >
                      Got it
                    </Button>

                    <LinkStyled
                      id={"manage-job-alerts-popup-link"}
                      href="/profile#manage-job-alerts"
                      target="_blank"
                      rel="noopener"
                    >
                      Manage Job Alerts
                    </LinkStyled>
                  </FrequencyContainer>
                </Fragment>
              ) : (
                <TitleStyled>
                  You have reached the maximum number of job alerts created.
                  Replace an existing alert to create the "{getCurrentKeyword()}
                  " alert.
                </TitleStyled>
              )}
            </DescriptionContainer>

            {props.reachedMaxJobAlerts && (
              <Fragment>
                {jobAlerts.slice(0, 5).map((jobAlert, index) => {
                  return (
                    <ReplaceAlertContainer>
                      <JobAlertStyled key={index}>
                        {jobAlert?.title}
                      </JobAlertStyled>
                      <Button
                        button_type={"OutlinedIndigo"}
                        mobile_width={"120px"}
                        font_size={"caption"}
                        padding={"8px 15px"}
                        onClick={handleReplace(jobAlert)}
                        disabled={props.replacingJobAlert}
                      >
                        Replace
                      </Button>
                    </ReplaceAlertContainer>
                  );
                })}
              </Fragment>
            )}
          </DialogContentStyled>
        </DialogStyled>
      )}
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    jobListFilter: state.jobs.jobListFilter,
    showJobAlertDialog: state.dialog.showJobAlertDialog,
    jobAlerts: state.jobs.jobAlerts,
    reachedMaxJobAlerts: state.jobs.reachedMaxJobAlerts,
    replacingJobAlert: state.jobs.replacingJobAlert,
    jobAlert: state.jobs.jobAlert,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateJobAlertDialog: bindActionCreators(updateJobAlertDialog, dispatch),
    hideSnackBar: bindActionCreators(hideSnackBar, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
    replaceJobAlert: bindActionCreators(replaceJobAlert, dispatch),
    updateJobAlert: bindActionCreators(updateJobAlert, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobAlertDialog);
