import { FontSize } from "../../../assets/font";
import Color from "../../../assets/colors";
import styled from "styled-components";
import withTheme from "@mui/styles/withTheme";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import DialogContent from "@mui/material/DialogContent";

// Dialog Components //
export const DialogStyled = withTheme(styled(Dialog)`
  && {
    .MuiPaper-root {
      border-radius: 20px;
      width: 70%;

      ${(props) => props.theme.breakpoints.down("sm")} {
        width: 100%;
      }
    }
  }
`);
export const DialogContentStyled = styled(DialogContent)`
  && {
    padding: 0 25px 25px 25px;
    align-items: center;
  }
`;

// Grid Components //
export const CloseContainer = styled(Grid)`
  && {
    padding: 10px 25px 0 25px;
    display: flex;
    justify-content: flex-end;
  }
`;
export const ContentContainer = withTheme(styled(Grid)`
  && {
    width: 100%;
    padding: 40px 50px 25px 50px;

    ${(props) => props.theme.breakpoints.down("sm")} {
      padding: 40px 10px 25px 10px;
    }
  }
`);
export const FormContainer = styled(Grid)`
  && {
    margin-top: 20px;
  }
`;
export const ButtonContainer = styled(Grid)`
  && {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

// Typography Components //
export const TitleStyled = styled(Typography)`
  && {
    font-size: ${FontSize.desktopHeadline2};
    font-weight: 600;
    text-align: center;
  }
`;
export const SubtitleStyled = styled(Typography)`
  && {
    font-size: ${FontSize.desktopBody};
    font-weight: 500;
  }
`;
export const HelperTextStyled = styled(Typography)`
  && {
    font-size: ${FontSize.desktopCaption};
    color: rgba(119, 114, 114, 1);
  }
`;

// Form Components //
export const LabelStyled = styled(FormControlLabel)`
  && {
    .MuiTypography-root {
      font-size: ${FontSize.desktopCaption};
    }
  }
`;
export const RadioStyled = styled(Radio)`
  && {
    &.Mui-checked {
      color: ${Color.hiredlyPurple};
    }
  }
`;

// Misc. Components //
export const CloseIconButton = styled(IconButton)`
  && {
    width: 40px;
    height: 40px;
  }
`;
