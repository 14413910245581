import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { styled as styling } from "@mui/material/styles";
import withTheme from "@mui/styles/withTheme";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import CheckCircleIcon from "@heroicons/react/24/solid/CheckCircleIcon";

import Color from "../../../assets/colors";
import { LoadingButton } from "@mui/lab";

export const DialogStyled = styling(Dialog)((props) => ({
  borderRadius: "20px",
}));

export const DialogTitleStyled = styling(DialogTitle)((props) => ({
  //
}));

export const DialogContentStyled = styling(DialogContent)((props) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: "0 100px 40px 100px",

  [props.theme.breakpoints.down("tablet")]: {
    padding: " 0 30px 45px 30px",
  },
}));

export const ButtonStyled = styling(LoadingButton)((props) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "12px 24px",
  background: "#6EC197",
  borderRadius: "8px",
  width: "100%",
  color: Color.white,
  textTransform: "none",
  maxWidth: "200px",

  "&:hover": {
    background: Color.black,
    color: Color.white,
  },
}));

export const IconButtonStyled = styling(IconButton)((props) => ({
  position: "absolute",
  top: 10,
  right: 10,
}));

export const CloseIconStyled = styling(XMarkIcon)((props) => ({
  height: "16px",
  width: "16px",
}));

export const CheckCircleFilledIcon = styling(CheckCircleIcon)((props) => ({
  height: "50px",
  width: "50px",
  color: Color.hiredlyPurple,
}));

export const TypographyStyled = withTheme(styled(Typography)`
  && {
    text-align: center;
    margin-bottom: ${(props) => (props.$subtitle ? "16px" : "8px")};
    padding: ${(props) => (props.$register ? "10px 24px" : "0px")};
    border-radius: ${(props) => (props.$register ? "10px" : "0px")};
    margin-top: ${(props) => (props.$register ? "24px" : "0px")};
    background: ${(props) =>
      props.$register ? Color.lightHiredlyPurple : "transparent"};
  }
`);

export const InputBaseStyled = styling(InputBase)((props) => ({
  border: `2px solid ${Color.secondaryGrey}`,
  borderRadius: "8px",
  padding: "8px 16px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  marginBottom: "35px",
}));

export const HyperlinkStyled = styling("a")((props) => ({
  color: Color.hiredlyPurple,
  textDecoration: "underline",
  cursor: "pointer",
}));
