import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import useTriggerSnackbar from "../../../hooks/useTriggerSnackbar";
import { updateVisitorJobAlertDialogDetails } from "../../../redux/actions/dialog_action";
import {
  updateShowJobAlertDialog,
  updateShowJobAlertSuccessDialog,
  visitorAddJobAlert,
} from "../../../redux/actions/job_action";
import * as types from "../../../redux/types/job_type";
import {
  ButtonStyled,
  CloseIconStyled,
  DialogContentStyled,
  DialogStyled,
  DialogTitleStyled,
  IconButtonStyled,
  InputBaseStyled,
  TypographyStyled,
} from "./styles";

function VisitorJobAlertDialog(props) {
  const openDialog = useSelector((state) => state.jobs?.showVisitorJobAlert);
  const jobAlertEmail = useSelector(
    (state) => state.dialog?.visitorEmailDialogInfo?.email
  );
  const searchKeyword = useSelector(
    (state) => state.dialog?.visitorEmailDialogInfo?.keyword
  );
  const dispatch = useDispatch();
  const [triggerSnackbarFunc] = useTriggerSnackbar();
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);

  const searchKeywordOrigin = useSelector(
    (state) => state.dialog?.jobAlertKeywordOrigin
  );

  const handleCloseDialog = () => {
    dispatch(updateShowJobAlertDialog(false));
  };

  const handleCreateJobAlert = async () => {
    setLoading(true);
    let result;

    const checkInputPassed = handleCheckInput();

    // Triggering api here
    if (checkInputPassed) {
      let params = {
        email: jobAlertEmail.trim(),
        title: searchKeyword.trim(),
      };
      result = dispatch(visitorAddJobAlert(params)).then((response) => {
        if (response?.type === types.VISITOR_ADD_JOB_ALERT_SUCCEED) {
          setLoading(false);
          dispatch(updateShowJobAlertDialog(false));
          dispatch(updateShowJobAlertSuccessDialog(true));
          dispatch(
            updateVisitorJobAlertDialogDetails({
              keyword: "",
            })
          );

          if (searchKeywordOrigin == "job-detail") {
            sendTrackingEvent({
              event: "CE_create-job-alert",
              origin: "job_detail",
              "job-alert-id": response.jobAlert?.id,
              "job-alert-frequency": response.jobAlert?.frequency,
              "job-alert-medium": response.jobAlert?.medium,
              keyword: searchKeyword.trim(),
            });
          } else if (searchKeywordOrigin == "job-alert-banner") {
            sendTrackingEvent({
              event: "CE_create-job-alert",
              origin: "job_list",
              "job-alert-id": response.jobAlert?.id,
              "job-alert-frequency": response.jobAlert?.frequency,
              "job-alert-medium": response.jobAlert?.medium,
              keyword: searchKeyword.trim(),
            });
          } else if (searchKeywordOrigin == "single-job-page") {
            sendTrackingEvent({
              event: "CE_create-job-alert",
              origin: "individual_job_page",
              "job-alert-id": response.jobAlert?.id,
              "job-alert-frequency": response.jobAlert?.frequency,
              "job-alert-medium": response.jobAlert?.medium,
              keyword: searchKeyword.trim(),
            });
          }
        } else if (
          response?.type === types.VISITOR_ADD_JOB_ALERT_FAILED &&
          response.error
        ) {
          setLoading(false);
          triggerSnackbarFunc({
            snackbarMessage: response.error.toString().trim(),
            severity: "error",
          });
        }
      });
    } else {
      setLoading(false);
      triggerSnackbarFunc({
        snackbarMessage:
          "Please make sure your email and job alert keyword is present",
        severity: "error",
      });
    }

    // Once api returns 200 success, close dialog and show success dialog
  };

  const handleCheckInput = () => {
    if (
      searchKeyword.trim() === "" ||
      (jobAlertEmail && jobAlertEmail.toString() && jobAlertEmail.trim() === "")
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <DialogStyled
      open={openDialog ? true : false}
      PaperProps={{ style: { borderRadius: "20px" } }}
    >
      <DialogTitleStyled>
        <IconButtonStyled onClick={handleCloseDialog}>
          <CloseIconStyled />
        </IconButtonStyled>
      </DialogTitleStyled>
      <DialogContentStyled>
        <TypographyStyled variant="h6">
          Get alerted with new jobs
        </TypographyStyled>

        <TypographyStyled variant="subtitle1" $subtitle>
          Let us know what you are looking for so we can recommend related jobs
          to you!
        </TypographyStyled>
        <InputBaseStyled
          placeholder="e.g. Internship"
          defaultValue={searchKeyword}
          autoFocus={true}
          onChange={(event) => {
            dispatch(
              updateVisitorJobAlertDialogDetails({
                keyword: event.target.value,
              })
            );
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleCreateJobAlert();
            }
          }}
        />
        <ButtonStyled loading={loading} onClick={handleCreateJobAlert}>
          Create job alert
        </ButtonStyled>
      </DialogContentStyled>
    </DialogStyled>
  );
}

export default VisitorJobAlertDialog;
