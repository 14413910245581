import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateJobAlertVisitorUnsubscribeDialog } from "../../../redux/actions/dialog_action";
import {
  DialogStyled,
  DialogContentStyled,
  CloseIconButton,
  CloseContainer,
  TitleStyled,
  SubtitleStyled,
  HelperTextStyled,
  ContentContainer,
  LabelStyled,
  RadioStyled,
  FormContainer,
  ButtonContainer,
} from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Button from "../../shared/SharedButton/SharedButton";
import {
  updateJobAlertToggle,
  updateJobListFilter,
  visitorUnsubscribeJobAlert,
} from "../../../redux/actions/job_action";
import { useRouter } from "next/router";
import SharedSpinner from "../../shared/SharedSpinner/SharedSpinner";

function JobAlertVisitorUnsubscribeDialog(props) {
  const { visitorAlert, visitorAlertID } = props;
  const [feedbackValue, setFeedbackValue] = useState("");
  const router = useRouter();

  const handleClose = () => {
    props.updateJobAlertVisitorUnsubscribeDialog(false);
    router.replace("/jobs");
  };

  const handleRadioClicked = (event) => {
    setFeedbackValue(event.target.value);
  };

  const handleSubmit = () => {
    if (!feedbackValue) return;

    let params = {
      id: visitorAlertID ? visitorAlertID : null,
      feedback: feedbackValue ? feedbackValue : "",
    };
    props.visitorUnsubscribeJobAlert(params).then(() => {
      props.updateJobAlertVisitorUnsubscribeDialog(false);
      router.replace("/jobs");
    });
  };

  return (
    <DialogStyled
      onClose={handleClose}
      open={props.showJobAlertVisitorUnsubscribeDialog}
    >
      <CloseContainer>
        <CloseIconButton onClick={handleClose}>
          <CloseIcon />
        </CloseIconButton>
      </CloseContainer>
      <DialogContentStyled>
        <TitleStyled>
          Your "{visitorAlert}" Job Alert is Successfully Unsubscribed
        </TitleStyled>
        <ContentContainer>
          <SubtitleStyled>Why are you unsubscribing?</SubtitleStyled>
          <HelperTextStyled>
            We're always working to improve our job alerts and your feedback
            matters.
          </HelperTextStyled>
          <FormContainer>
            <FormControl>
              <RadioGroup
                aria-labelledby="feedback-radio-buttons"
                onChange={handleRadioClicked}
              >
                <LabelStyled
                  value="I found a job!"
                  control={<RadioStyled />}
                  label="I found a job!"
                />
                <LabelStyled
                  value="Jobs shown are not what I'm looking for"
                  control={<RadioStyled />}
                  label="Jobs shown are not what I'm looking for"
                />
                <LabelStyled
                  value="No longer looking for this kind of work"
                  control={<RadioStyled />}
                  label="No longer looking for this kind of work"
                />
                <LabelStyled
                  value="Too many emails"
                  control={<RadioStyled />}
                  label="Too many emails"
                />
                <LabelStyled
                  value="Other"
                  control={<RadioStyled />}
                  label="Other"
                />
              </RadioGroup>
            </FormControl>
          </FormContainer>
        </ContentContainer>
        <ButtonContainer>
          <Button
            button_type={"SolidPurple"}
            desktop_width={"Long"}
            onClick={handleSubmit}
          >
            {props.visitorUnsubscribingJobAlert ? (
              <SharedSpinner width={20} height={20} type={"white"} />
            ) : (
              "Submit"
            )}
          </Button>
        </ButtonContainer>
      </DialogContentStyled>
    </DialogStyled>
  );
}

const mapStateToProps = (state) => {
  return {
    showJobAlertVisitorUnsubscribeDialog:
      state.dialog.showJobAlertVisitorUnsubscribeDialog,
    visitorUnsubscribingJobAlert: state.jobs.visitorUnsubscribingJobAlert,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateJobAlertVisitorUnsubscribeDialog: bindActionCreators(
      updateJobAlertVisitorUnsubscribeDialog,
      dispatch
    ),
    visitorUnsubscribeJobAlert: bindActionCreators(
      visitorUnsubscribeJobAlert,
      dispatch
    ),
    updateJobAlertToggle: bindActionCreators(updateJobAlertToggle, dispatch),
    updateJobListFilter: bindActionCreators(updateJobListFilter, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobAlertVisitorUnsubscribeDialog);
