import React, { Fragment } from "react";
import {
  AiSummaryErrorWrapper,
  AiErrorImageBox,
  AiErrorImage,
  AiSummaryErrorBox,
  AiSummaryErrorText,
  LinkStyled,
} from "./styles";
import * as config from "../../../config/config";
import { useSelector } from "react-redux";

function AiJobSummaryError(props) {
  const currentJob = useSelector((state) => state.jobs?.job);

  return (
    <AiSummaryErrorWrapper>
      <AiErrorImageBox>
        <AiErrorImage
          src={
            config.assetDomain +
            "/images/jobseeker-illustrations/hiredly-job-search-empty-placeholder.svg"
          }
          alt="error image"
        />
      </AiErrorImageBox>
      <AiSummaryErrorBox>
        <Fragment>
          <AiSummaryErrorText>Error loading AI Summary.</AiSummaryErrorText>
          <AiSummaryErrorText>
            Please try again later or{" "}
            <LinkStyled
              href={`/jobs/${currentJob?.slug}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              click here
            </LinkStyled>{" "}
            to view job details.
          </AiSummaryErrorText>
        </Fragment>
      </AiSummaryErrorBox>
    </AiSummaryErrorWrapper>
  );
}

export default AiJobSummaryError;
