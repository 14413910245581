import BriefcaseIcon from "@heroicons/react/24/outline/BriefcaseIcon";
import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import withTheme from "@mui/styles/withTheme";
import styled from "styled-components";
import Color from "../../../assets/colors";

// Button Component //
export const InternshipFilterButton = withTheme(styled(Button)`
  && {
    border-radius: 0.5rem;
    border: ${(props) =>
      props.$internship_active === "true"
        ? `2px solid ${Color.hiredlyPurple}`
        : `1.5px solid ${Color.black}`};
    font-weight: bold;
    text-transform: capitalize;
    height: 38px;
    width: 100%;
    margin: 0 5% 2% 5%;
    padding: 0 0.5rem;
    justify-content: space-between;
    color: ${(props) =>
      props.$internship_active === "true" ? Color.hiredlyPurple : Color.black};
    background-color: ${(props) =>
      props.$internship_active === "true" ? Color.lightPurple : null};

    &:hover {
      background-color: transparent;
      border: 1.5px solid ${Color.black};
    }

    ${(props) => props.theme.breakpoints.up("tablet")} {
      display: none;
    }
  }
`);

// Tooltip Component //
export const AshleyToggleTooltip = styling(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: Color.white,
    padding: "0.75rem 1.25rem",
    borderRadius: "0.75rem",
    color: Color.chipGrey,
    border: `2px solid ${Color.black}`,
  },
}));

// Box Components //
export const ReverseGradient = styling(Box)((props) => ({
  width: "75px",
  aspectRatio: "1",
  background: `linear-gradient(45deg, ${Color.hiredlyPurple}, ${Color.white})`,
  borderRadius: "45%",
  position: "absolute",
  animation: `move2 3s cubic-bezier(0, 0, 0.5, 1) infinite`,
  right: "0",
  bottom: "-50px",

  "@keyframes move2": {
    "0%": {
      right: "0",
      bottom: "-50px",
      transform: "rotate(0deg)",
    },
    "50%": {
      right: "55%",
      bottom: "-50px",
      transform: "rotate(180deg)",
    },
    "100%": {
      right: "55%",
      bottom: "0px",
      transform: "rotate(360deg)",
    },
  },

  [props.theme.breakpoints.down("tablet")]: {
    width: "120px",

    "@keyframes move2": {
      "0%": {
        right: "0",
        bottom: "-105px",
        transform: "rotate(0deg)",
      },
      "50%": {
        right: "55%",
        bottom: "-105px",
        transform: "rotate(180deg)",
      },
      "100%": {
        right: "55%",
        bottom: "0px",
        transform: "rotate(360deg)",
      },
    },
  },
}));

export const Gradient = styling(Box)((props) => ({
  width: "75px",
  aspectRatio: "1",
  background: `linear-gradient(45deg, ${Color.hiredlyPurple}, ${Color.white})`,
  borderRadius: "45%",
  position: "absolute",
  animation: `move 3s cubic-bezier(0, 0, 0.5, 1) infinite`,
  left: "0",
  top: "0",

  "@keyframes move": {
    "0%": {
      left: "0",
      top: "-50px",
      transform: "rotate(0)",
    },
    "50%": {
      left: "55%",
      top: "-50px",
      transform: "rotate(180deg)",
    },
    "100%": {
      left: "55%",
      top: "-30px",
      transform: "rotate(360deg)",
    },
  },

  [props.theme.breakpoints.down("tablet")]: {
    width: "120px",

    "@keyframes move": {
      "0%": {
        left: "0",
        top: "-105px",
        transform: "rotate(0)",
      },
      "50%": {
        left: "55%",
        top: "-105px",
        transform: "rotate(180deg)",
      },
      "100%": {
        left: "55%",
        top: "-30px",
        transform: "rotate(360deg)",
      },
    },
  },
}));

export const Blur = styling(Box)((props) => ({
  background: "#ffffff66",
  backdropFilter: "blur(10px)",
  height: "100%",
  width: "100%",
  position: "absolute",
  top: "0",
  left: "0",
}));

// Switch Component //
export const AshleyToggle = withTheme(styled(Switch).attrs(() => ({
  classes: {
    root: "root",
    switchBase: "switchBase",
    thumb: "thumb",
    track: "track",
    checked: "checked",
  },
  disableRipple: true,
}))`
  &.root {
    width: 43px;
    height: 24px;
    padding: 0;
    margin: 8px 0 8px;

    ${(props) => props.theme.breakpoints.between("tablet", "lg")} {
      margin: 0;
    }
  }

  .switchBase {
    padding: 3px 3px 3px 5px;

    &.checked {
      transform: translateX(15px);
      & + .track {
        background-color: ${Color.hiredlyPurple};
        opacity: 1;
        border: none;
      }
    }
  }

  .thumb {
    width: 18px;
    height: 18px;
    color: ${Color.white};
  }

  & .track {
    border-radius: 13px;
    background-color: ${Color.grey};
    opacity: 1;
  }
`);

// Icon Component //
export const BriefcaseIconStyled = styling(BriefcaseIcon)({
  width: "1.25rem",
  height: "1.25rem",
});

export const InfoIconStyled = styling(InfoIcon)((props) => ({
  width: "0.75rem",
  height: "0.75rem",
  cursor: "pointer",

  [props.theme.breakpoints.down("tablet")]: {
    width: "1.25rem",
    height: "1.25rem",
  },
}));

// Typography Components //
export const LabelStyled = withTheme(styled(Typography)`
  && {
    text-transform: capitalize;
    color: ${(props) => (props.$color ? `${Color.secondaryGrey}` : null)};
  }
`);

export const AshleyToggleText = withTheme(styled(Typography)`
  && {
    color: ${(props) => (props.$color ? `${Color.hiredlyPurple}` : null)};
    font-weight: ${(props) => (props.$bold ? "700" : null)};
    font-size: ${(props) => (props.$font_size ? "0.5rem" : null)};
    position: ${(props) => (props.$absolute ? "absolute" : null)};
    right: 0.3rem;
    top: -0.25rem;

    ${(props) => props.theme.breakpoints.between("tablet", "lg")} {
      font-size: ${(props) => (props.$font_size ? "0.375rem" : "0.625rem")};
      right: -0.5rem;
    }

    ${(props) => props.theme.breakpoints.down("tablet")} {
      font-size: ${(props) => (props.$font_size ? "0.5rem" : null)};
      left: 5.3rem;
      top: 0;
    }
  }
`);

// Grid Components //
export const FilterCategory = styling(Grid)({
  display: "flex",
  alignItems: "baseline",
  whiteSpace: "nowrap",
});

export const JobPageContainer = styling(Grid)((props) => ({
  padding: "0 6.25rem",
  maxWidth: "1440px",
  margin: "auto",
  paddingBottom: "2%",

  [props.theme.breakpoints.between("tablet", "lg")]: {
    padding: "0 1.5rem",
  },

  [props.theme.breakpoints.down("tablet")]: {
    padding: "0 1rem",
  },

  [props.theme.breakpoints.between("tablet", "tabletL")]: {
    padding: "0 1rem 3.25rem 1rem",
  },
}));

export const JobCompanySection = styling(Grid)((props) => ({
  height: "calc(100vh - 90px)",
  overflow: "auto",
  maxWidth: "100%",
  flex: "1",
  margin: "0 0 0 24px",
  paddingTop: "8px",
  paddingRight: "16px",

  [props.theme.breakpoints.between("tablet", "lg")]: {
    paddingRight: "14px",
  },
}));

export const JobSearchContainer = styling(Box)((props) => ({
  display: "flex",
  alignItems: "center",
  gap: "0.625rem",
  backgroundColor: Color.backgroundGrey,
  width: "100%",
  margin: "2rem 0 1.5rem 0",

  [props.theme.breakpoints.down("lg")]: {
    margin: "1.5rem 0 1rem 0",
    width: "100%",
  },
  [props.theme.breakpoints.down("tablet")]: {
    margin: "1.5rem 0 0.5rem 0",
    justifyContent: "center",
    width: "100%",
  },
}));

export const JobSearchOptionsGrid = styling(Grid)({
  backgroundColor: Color.backgroundGrey,
});

export const AshleyToggleGrid = withTheme(styled(Grid)`
  && {
    position: ${(props) => (props.$absolute ? "absolute" : "relative")};
    top: ${(props) => (props.$absolute ? "0" : null)};
    left: ${(props) => (props.$absolute ? "0" : null)};
    box-shadow: ${(props) =>
      props.$toggle_on === "true"
        ? `-1px -1px 6px ${Color.white}, 1px 1px 4px rgba(0, 0, 0, 0.3)`
        : "inset 0px 2px 2px -1px rgba(74, 74, 104, 0.4)"};
    background-color: ${(props) =>
      props.$toggle_on === "true" ? `${Color.lightGrey}` : null};
    overflow: hidden;
    background-color: transparent;
    height: 2.5rem;
    width: 12rem;
    border-radius: 6.25rem;
    padding: 0.25rem 0.25rem 0.25rem 1rem;

    ${(props) => props.theme.breakpoints.between("tablet", "lg")} {
      width: 10rem;
      height: 2rem;
    }

    ${(props) => props.theme.breakpoints.down("tablet")} {
      width: 100%;
      height: 2.5rem;
      padding: 0.75rem 0.75rem 0.75rem 1rem;
    }
  }
`);

export const AshleyToggleContentGrid = styling(Grid)({
  position: "relative",
});

export const AshleyToggleGridInner = withTheme(styled(Grid)`
  && {
    position: absolute;
    top: 0;
    left: 0;
    padding: ${(props) => (props.$mobile ? "0 15px" : "0 10px")};
    height: 100%;
    gap: 10px;
  }
`);

export const JobContainer = styling(Grid)({
  display: "flex",
  flex: "1",
  width: "100%",
});

export const JobDetailsContainer = styling(Grid)({
  borderRadius: "20px",
  backgroundColor: Color.white,
  padding: "24px 16px",
  margin: "24px 0 0 0",
});

export const Wrapper = styling(Grid)({
  backgroundColor: Color.backgroundGrey,
  minHeight: "100vh",
  height: "100%",
});

// Scrollbar Component //
export const useStyles = makeStyles((theme) => ({
  scrollBar: {
    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
      backgroundColor: Color.grey,
      width: 4,
      overflow: "auto",
    },
    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
      borderRadius: 8,
      backgroundColor: Color.hiredlyPurple,
      minHeight: 24,
      overflow: "auto",
    },
    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
      borderRadius: 8,
      backgroundColor: Color.lightHiredlyPurple,
      minHeight: 24,
      overflow: "auto",
    },
  },
}));
