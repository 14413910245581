import DocumentTextIcon from "@heroicons/react/24/outline/DocumentTextIcon";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import withTheme from "@mui/styles/withTheme";
import styled from "styled-components";
import Color from "../../../assets/colors";

// Button Component //
export const AiSummaryButton = styling(Button)((props) => ({
  textTransform: "uppercase",
  fontWeight: "400",
  letterSpacing: "1.5px",
  fontSize: "0.625rem",
  width: "7.25rem",
  height: "1.5rem",
  borderRadius: "0.25rem",
  padding: "0",
  margin: "0 0.25rem",
  cursor: "default",

  ":hover": {
    backgroundColor: "transparent",
  },
}));

// Grid Components //
export const OuterContainer = styling(Box)((props) => ({
  display: "flex",
  gap: "1rem",

  width: "100%",
  margin: "0 auto 2rem",

  [props.theme.breakpoints.down("sm")]: {
    marginTop: "0.725rem",
  },
}));

export const JobListContainer = styling(Box)((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",

  width: "70%",
  margin: "0 auto",

  [props.theme.breakpoints.down("lg")]: {
    width: "100%",
  },

  [props.theme.breakpoints.down("sm")]: {
    width: "90%",
  },
}));

export const JobCardContainer = styling(Box)({});

export const EmptyContainer = styling(Grid)({
  //
});

// Icon Button Component
export const IconButtonStyled = withTheme(styled(IconButton)`
  && {
    position: ${(props) =>
      props.$position === "absolute" ? "absolute" : null};
    right: ${(props) => (props.$right === "true" ? "0" : null)};
    top: ${(props) => (props.$top === "true" ? "0.5rem" : null)};
    margin-left: ${(props) =>
      props.$margin_left === "true" ? "0.5rem" : null};
    margin-right: ${(props) =>
      props.$margin_right === "true" ? "0.5rem" : null};
    background-color: ${(props) =>
      props.$pagination === "true" ? Color.grey : null};
    color: ${(props) => (props.$pagination === "true" ? Color.darkGrey : null)};
    width: ${(props) => (props.$pagination === "true" ? "2rem" : null)};
    height: ${(props) => (props.$pagination === "true" ? "2rem" : null)};
    border-radius: ${(props) =>
      props.$pagination === "true" ? "0.25rem" : null};

    &:hover {
      color: ${(props) =>
        props.$pagination === "true" ? Color.hiredlyPurple : null};
      background-color: ${(props) =>
        props.$pagination === "true" ? Color.grey : null};
    }
  }
`);

// Icon Components
export const DocumentTextIconStyled = styling(DocumentTextIcon)({
  width: "1rem",
  height: "1rem",
});

export const ChevronLeftIconStyled = styling(ChevronLeftIcon)({
  width: "1.25rem",
  height: "1.25rem",
});

export const ChevronRightIconStyled = styling(ChevronRightIcon)({
  width: "1.25rem",
  height: "1.25rem",
});

// Box Component
export const PaginationNumberBox = withTheme(styled(Box)`
  && {
    border-radius: 0.25rem;
    background-color: ${(props) =>
      props.$non_focus === "true" ? null : Color.hiredlyPurple};
    color: ${(props) =>
      props.$non_focus === "true" ? "#00000040" : Color.white};
    margin: ${(props) => (props.$margin === "true" ? "0 1.25rem" : null)};
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`);

export const AiSummaryWrapper = styling(Box)((props) => ({
  position: "relative",
  display: "grid",
  alignItems: "center",
  justifyItems: "center",
  gridTemplateRows: "1fr",
  width: "22.5rem",
  height: "32.25rem",
  position: "sticky",
  top: 100,
  border: "2px dashed rgba(81, 42, 204, 0.2)",
  borderRadius: "1.25rem",

  [props.theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));

export const AiFileImageBox = styling(Box)({
  position: "absolute",
  bottom: "55%",
});

export const AiSummaryEmptyBox = styling(Box)({
  position: "relative",
});

// Typography Component
export const PageNumber = styling(Typography)({
  //
});

// Span Component
export const AiSummaryEmptyText = styling("span")({
  fontSize: "0.875rem",
  fontWeight: "400",
});

// Image Component
export const AiFileImage = styling("img")({
  //
});

export const AiArrowImage = styling("img")({
  position: "absolute",
  left: "0",
  top: "1.5rem",
});
