import moment from "moment/moment";
import api from "../utils/api";

const LINKEDIN_SCOPE = process.env.LINKEDIN_SCOPE;
const LINKEDIN_REDIRECT = process.env.LINKEDIN_REDIRECT_URI;
const LINKEDIN_CLIENT_ID = process.env.LINKEDIN_ID;

export const getURLWithQueryParams = (base, params) => {
  const query = Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");

  return `${base}?${query}`;
};

export const LINKEDIN_URL = () => {
  let oauthUrl = new URL("https://www.linkedin.com/oauth/v2/authorization");
  oauthUrl.searchParams.append("response_type", "code");
  oauthUrl.searchParams.append("client_id", LINKEDIN_CLIENT_ID);
  oauthUrl.searchParams.append("redirect_uri", LINKEDIN_REDIRECT);
  oauthUrl.searchParams.append("scope", LINKEDIN_SCOPE);

  return oauthUrl;
};

// For Lightcast API access token
export const checkLightCastTokenTimeOut = () => {
  const tokenTimeOut = localStorage.getItem("lightcast-token-timeout");
  const storedToken = localStorage.getItem("lightcast-token");

  // If local storage timeout value is not null and has already past the current time, refresh
  if (
    (storedToken &&
      tokenTimeOut &&
      moment(tokenTimeOut).diff(moment(), "minutes") < 0) ||
    !tokenTimeOut ||
    !storedToken
  ) {
    api.obtainLightCastToken();
  }
};
